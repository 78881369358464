import { v4 as uuid } from 'uuid';
import { PublicReplyOptions, ReplyModalMode } from '../../dashboard/components/comments-viewer/comments-viewer.constants';
import { dashboardTabConstants } from '../../dashboard/constants';
import { Template } from '../../templates/templates.types';
import { ALLCONSTANT, campaignTypes, createForOptions, PrivateReplyActionType } from '../constants';
import { CampaignTypeUI, ConditionType, GroupConditionType, IfStatementType, ResponseActionType, TagSelectOption, TagSelectOptions } from '../types';

export const addIdstoTragetingConditionsAndCreateFor = (conditions: ConditionType) => {
  const childConditions = conditions.childConditions.map((condition: GroupConditionType) => {
    const ifStatements = condition.ifStatements || [];
    return {
      ...condition,
      id: uuid(),
      ifStatements: ifStatements.map((statement: IfStatementType) => {
        return { ...statement, id: uuid() };
      }),
    };
  });

  const conditionsWithIds: ConditionType = {
    ...conditions,
    childConditions,
  };
  return { conditionsWithIds };
};

export const getCreatedForObject = (commentType?: string, campaignType?: string, targetPostMentions?: boolean) => {
  const postMentions = { [dashboardTabConstants.POST_MENTIONS]: !!targetPostMentions };

  if (!commentType && !targetPostMentions) {
    if (campaignType === campaignTypes.TAGGING) {
      return { [dashboardTabConstants.COMMENTS]: true, [dashboardTabConstants.MENTIONS]: true };
    }
    return { [dashboardTabConstants.COMMENTS]: true };
  }
  if (commentType === ALLCONSTANT) {
    const allCreateForOptions = createForOptions.reduce((acc: any, x: any) => {
      return { ...acc, [x.value]: true };
    }, {});
    return { ...allCreateForOptions, ...postMentions };
  } else if (commentType === dashboardTabConstants.MENTIONS) {
    return { [dashboardTabConstants.MENTIONS]: true, ...postMentions };
  } else if (commentType === dashboardTabConstants.COMMENTS) {
    return { [dashboardTabConstants.COMMENTS]: true, ...postMentions };
  }
  return { ...postMentions };
};

export const isValidTargetingConditions = (conditions: ConditionType) => {
  const conditionGroups = (conditions && conditions.childConditions) || [];
  function checkInValidCondition(conditionGroup: GroupConditionType) {
    const ifStatements = conditionGroup.ifStatements || [];
    function checkInvalidStatement(ifStatement: IfStatementType) {
      if (!ifStatement.variable?.macro) {
        return true;
      }
    }
    return ifStatements.some(checkInvalidStatement);
  }
  return !conditionGroups.some(checkInValidCondition);
};

export const validateResponseActions = (actions: ResponseActionType[] = [], mediaUrl: string) => {
  const isError = actions.some((action: ResponseActionType) => {
    if ([PrivateReplyActionType.TEXT, PrivateReplyActionType.PUBLIC, PrivateReplyActionType.TAG, PrivateReplyActionType.PRIVATE].includes(action.type)) {
      return !action.replyMessage && !mediaUrl;
    }
    if ([PrivateReplyActionType.MODULE].includes(action.type)) {
      return !action.replyNodes || !action.replyNodes.length;
    }
    return false;
  });
  return !isError;
};

export const isValidCampaign = (campaignData: CampaignTypeUI, campaignTagsAsOptions: TagSelectOption[], mediaUrl?: string, allowAdvancedConditions?: boolean) => {
  const isValidCampaign =
    campaignData.name &&
    ((isSelectedTagValid(campaignData.selectedTag, campaignTagsAsOptions) && validateResponseActions(campaignData.actions, mediaUrl || '')) ||
      (allowAdvancedConditions && campaignData?.hasAdvancedConditions));
  return isValidCampaign;
};
export const isSelectedTagValid = (selectedTag: string | undefined, campaignTagsAsOptions: TagSelectOption[]) => {
  const selectedTagOption = getSelectedTagOption(selectedTag, campaignTagsAsOptions);
  const isSelectedTagOptionValid = selectedTagOption.length ? true : false;
  return isSelectedTagOptionValid;
};
export const getSelectedTagOption = (selectedTag: string | undefined, campaignTagsAsOptions: TagSelectOption[]) => {
  return (selectedTag && campaignTagsAsOptions?.length && campaignTagsAsOptions.find((tag: TagSelectOption) => tag.value === selectedTag) && [selectedTag]) || [];
};
export const findTagWithTagName = (tagName: string, allTags: TagSelectOptions) => {
  const tag = allTags.find((tag: TagSelectOption) => {
    if (!tag.value || !tagName) {
      return false;
    }
    return tag.value.toLowerCase() === tagName.toLowerCase();
  });
  return tag;
};

export const checkActionConflict = (TagSelectOption?: TagSelectOption, campaignActions?: ResponseActionType[]) => {
  if (TagSelectOption && TagSelectOption.action && campaignActions) {
    const tagAction = TagSelectOption.action;
    return campaignActions.some((campaignAction: { type: string }) => campaignAction.type !== tagAction && tagAction !== 'noaction');
  }
  return false;
};

export const getPublicReplyTypeOptions = (templates: { standardTemplates: Template[]; customTemplates: Template[] }) => {
  const optionsObj = { ...PublicReplyOptions };
  if (![...templates.standardTemplates, ...templates.customTemplates].length) {
    delete optionsObj[ReplyModalMode.TEMPLATE];
  }
  return Object.keys(optionsObj).map((value: string) => ({ label: optionsObj[value], value }));
};

export const isActionRich = (selectedAction: string) => selectedAction === PrivateReplyActionType.MODULE;

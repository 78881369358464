import { fbPageDeploymentSuccess, showFbPageList, storeFbPages, storeInstagramPages, toggleFbWarningModalDisplay } from '.';
import { post } from '../../../common/api-calls/api-client';
import { getBotsAndPlatformBots } from '../../../common/entities/actions/async-actions';
import { setInitialDataFetchAttempted } from '../../../common/page-ui-state/actions';
import { showErrorModal } from '../../../common/utils';
import { logger } from '../../../common/utils/logger';
import { AsyncThunkDispatch, RootState, Thunk } from '../../../types';
import { showMessage } from '../../common/toast-notification';
import { SuccessText } from '../../common/toast-notification/toast-notification.constants';
import { FBDeployPage } from '../types';
export const checkFbApprovedPermissions = ({
  accessToken,
  fbUserId,
  signedRequest,
  onFetchFbPagesDone,
  isFbSystemUser,
  accountId,
}: {
  accessToken: string;
  fbUserId: string;
  signedRequest: string;
  onFetchFbPagesDone?: (fbPageList?: any) => void;
  isFbSystemUser?: boolean;
  accountId?: string;
}): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth?.authToken;
    const url = `facebook/permissions`;
    const body = {
      accessToken,
      fbUserId,
      signedRequest,
      platform: 'facebook',
      isAmplifyLite: true,
      botId: '', // This is required field in API so sending it.
    };
    try {
      const res = await post(url, body, authToken as string);
      const permissionsList = res && res.data;
      if (permissionsList.approved.length === 0 && permissionsList.rejected.length === 0) {
        // eslint-disable-next-line no-console
        console.log('approved and rejected list empty.');
        dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: false }));
      }
      // existing user - rejected required permissions
      else if (permissionsList.requiredRejected.length !== 0) {
        // eslint-disable-next-line no-console
        console.log('requiredRejected is not empty', permissionsList.requiredRejected);
        dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
      }
      // existing user - new permissions added
      else if (permissionsList.additionalRequired.length !== 0) {
        // eslint-disable-next-line no-console
        console.log('additionalRequired is not empty', permissionsList.additionalRequired);
        dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
      }
      // existing user - granted all permissions
      else if (permissionsList.requiredRejected.length === 0) {
        // eslint-disable-next-line no-console
        console.log('requiredRejected is not empty', permissionsList.requiredRejected);
        // dispatch(reloadPageList(userId, accessToken, fbUserId, signedRequest, botId));
        dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: false }));
        dispatch(fetchFbPages({ accessToken, fbUserId, signedRequest, done: onFetchFbPagesDone, isFbSystemUser, accountId }));
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchFbPages = ({
  accessToken,
  fbUserId,
  signedRequest,
  done,
  isFbSystemUser,
  accountId: accountIdFromParam,
}: {
  accessToken: string;
  fbUserId: string;
  signedRequest: string;
  accountId?: string;
  done?: (fbPageList?: any) => void;
  isFbSystemUser?: boolean;
}): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const accountId = state.entities.account?.id || accountIdFromParam;
    const authToken = state.auth?.authToken;
    const url = `facebook/pagelist`;
    const body = {
      accountId,
      // fetchInstagramDetails: true,
      isFbSystemUser:
        isFbSystemUser !== undefined
          ? isFbSystemUser
          : state?.auth?.permissions?.botPermissions &&
            (state?.auth?.permissions?.botPermissions['*'] || state?.auth?.permissions?.botPermissions['deployAsFbBusinessManagerSystemUser']),
      fbAuthResponse: {
        fbUserId,
        accessToken,
        platform: 'facebook',
        signedRequest,
        botId: '', // This is required field in API so sending it.
      },
    };
    post(url, body, authToken!)
      .then((res: any) => {
        const pageList = res && res.data;
        dispatch(storeFbPages({ fbPageList: pageList }));
        dispatch(showFbPageList({ showFbPageList: true }));
        if (done) {
          done(pageList);
        }
      })
      .catch((error: any) => {
        showErrorModal(error, dispatch);
      });
  };
};
//eslint-disable-next-line max-lines-per-function
export const deployFbPage = (
  {
    pageDetails,
    fbUserId,
    accessToken,
    signedRequest,
    isFbSystemUser,
  }: {
    pageDetails: FBDeployPage;
    fbUserId: string;
    accessToken: string;
    signedRequest: string;
    isFbSystemUser: boolean;
  },
  templateSelected: string,
): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth?.authToken;
    const accountId = state.entities.account?.id!;
    const url = 'bots/deployAmplifyLiteBot';
    const body: any = {
      isFbSystemUser,
      pageDetails: {
        category: pageDetails?.category,
        name: pageDetails?.name,
        id: pageDetails?.id,
      },
      accountId,
      instagramDeploymentDetails: undefined,
      fbAuthResponse: {
        fbUserId,
        accessToken,
        botId: '',
        platform: 'facebook',
        signedRequest,
      },
      template: {
        botTemplateType: templateSelected,
      },
    };
    logger.log('Inside deployFbPage : async action triggered');
    if (pageDetails?.instagramEnabled) {
      body.instagramDeploymentDetails = { instagramAccountId: pageDetails?.instagramAccountId };
    }
    logger.log('Inside deployFbPage : calling API deployAmplifyLiteBot');
    post(url, body, authToken!)
      .then(() => {
        logger.log('Inside deployFbPage : deployment successful');
        dispatch(showFbPageList({ showFbPageList: false }));
        dispatch(fbPageDeploymentSuccess({ fbPageDeployed: true }));
        dispatch(getBotsAndPlatformBots(accountId));
        showMessage(`${pageDetails?.name} deployed ${SuccessText}`);
      })
      .catch((error: any) => {
        showErrorModal(error, dispatch);
        logger.error('Inside deployFbPage : deployment failed');
        dispatch(setInitialDataFetchAttempted(true));
      });
  };
};

export const deployBot = ({
  pageDetails,
  fbUserId,
  accessToken,
  signedRequest,
  isFbSystemUser,
  botId,
  onDone,
}: {
  pageDetails: FBDeployPage;
  fbUserId: string;
  accessToken: string;
  signedRequest: string;
  isFbSystemUser: boolean;
  botId: string;
  onDone?: () => void;
  onError?: () => void;
}): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth?.authToken;
    const url = `bots/${botId}/deploy`;
    const body: any = {
      isFbSystemUser,
      pageDetails: {
        category: pageDetails?.category,
        name: pageDetails?.name,
        id: pageDetails?.id,
      },
      fbAuthResponse: {
        fbUserId,
        accessToken,
        botId,
        platform: 'facebook',
        signedRequest,
      },
    };
    logger.log('Inside deployBot : async action triggered');
    if (pageDetails?.instagramEnabled) {
      body.instagramDeploymentDetails = { instagramAccountId: pageDetails?.instagramAccountId };
    }
    logger.log('Inside deployBot : calling API deployAmplifyLiteBot');
    post(url, body, authToken!)
      .then(() => {
        logger.log('Inside deployBot : deployment successful');
        dispatch(showFbPageList({ showFbPageList: false }));
        dispatch(fbPageDeploymentSuccess({ fbPageDeployed: true }));
        showMessage(`${pageDetails?.name} deployed ${SuccessText}`);
        onDone && onDone();
      })
      .catch((error: any) => {
        showErrorModal(error, dispatch);
        logger.error('Inside deployBot : deployment failed');
        dispatch(setInitialDataFetchAttempted(true));
      });
  };
};

export const deployAmplifyLiteBot = (
  {
    instagramAccountId,
    fbPageId,
    pageName: name,
    pageCategory: category,
    name: instaPgName,
  }: {
    instagramAccountId?: string;
    fbPageId?: string;
    pageName?: string;
    pageCategory?: string;
    fbPageName?: string;
    name?: string;
  },
  templateSelected: string,
  isFbSystemUser?: boolean,
): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = '/bots/deployAmplifyLiteInstagramBot';
    /*eslint-disable no-console*/
    const {
      auth: { authToken },
      entities,
      fbDeploy,
    } = getState();
    const accountId = entities?.account?.id!;
    const fbUserAccessToken = fbDeploy?.instaAccessToken;
    const body = {
      accountId,
      instagramAccountId,
      fbPageId,
      ...(isFbSystemUser ? { isFbSystemUser } : { fbUserAccessToken }),
      deploymentDetails: {
        page: {
          name,
          category,
        },
      },
      template: {
        botTemplateType: templateSelected,
      },
    };
    logger.log('Inside deployAmplifyLiteBot : async action triggered');
    logger.log('Inside deployAmplifyLiteBot : calling API deployAmplifyLiteInstagramBot');
    post(url, body, authToken!)
      .then(() => {
        logger.log('Inside deployAmplifyLiteBot : deployment successful');
        dispatch(showFbPageList({ showFbPageList: false }));
        dispatch(fbPageDeploymentSuccess({ fbPageDeployed: true }));
        dispatch(getBotsAndPlatformBots(accountId));
        showMessage(`${instaPgName} deployed ${SuccessText}`);
      })
      .catch((error: any) => {
        showErrorModal(error, dispatch);
        logger.error('Inside deployAmplifyLiteBot : deployment failed');
        dispatch(setInitialDataFetchAttempted(true));
      });
  };
};

export const unlinkPage = (platformBotId: string, done?: () => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth?.authToken;
    const botId = state.entities.platformBots ? state.entities.platformBots[platformBotId].botId : '';
    const url = `bots/${botId}/rollback`;
    const body = {
      platformBotId,
      isAmplifyLite: true,
    };
    post(url, body, authToken!)
      .then((response: { success: boolean }) => {
        if (response) done && done();
      })
      .catch((error: any) => {
        showErrorModal(error, dispatch);
      });
  };
};

export const fetchInstagramPages = ({
  isFbSystemUser,
  platformBotIds,
  fbAccessToken,
  done,
  redirectUri,
}: {
  isFbSystemUser: boolean;
  platformBotIds: string[];
  fbAccessToken: string;
  done?: () => void;
  redirectUri: string;
}): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const accountId = state.entities.account?.id;
    const authToken = state.auth?.authToken;
    const url = `facebook/getInstagramAccountIds`;

    const body = {
      accountId,
      isFbSystemUser,
      platformBotIds,
      fbAccessToken,
      redirectUri,
    };
    post(url, body, authToken!)
      .then((res: any) => {
        const pageList = res && res.data;
        dispatch(storeInstagramPages(pageList));
        done && done();
      })
      .catch((error: any) => {
        showErrorModal(error, dispatch);
      });
  };
};

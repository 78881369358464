import React from 'react';
import { ReactComponent as FacebookIcon } from '../../../assets/svg/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/svg/instagram.svg';
import styles from '../InvitationAccepted.module.scss';
import { useCheckFbLogin, useInstagramStandaloneLogin } from 'src/hooks';
import { registerAmplifyAnalytics } from 'src/analytics/utils';
import { useDispatch } from 'react-redux';
import { checkFbApprovedPermissions } from 'src/pages/fb-login/actions';
import { useTillerInviteContext } from '../hooks';
import { platforms } from 'src/common/entities/entities.constants';
import { showMessage } from 'src/pages/common/toast-notification';

const SocialLoginButton = () => {
  const { trillerInvite } = useTillerInviteContext();
  const onFetchFbPagesDone = (fbPages: any) => {
    if (!fbPages?.currentUserPages?.length) {
      showMessage('No facebook pages found', 'Please make sure you have pages in your account', 'error');
    }
  };
  const dispatch = useDispatch();
  const onLoginSuccessForConnectFbAccount = (accessToken?: string, userID?: string, signedRequest?: string, socialMedia: string = 'facebook') => {
    registerAmplifyAnalytics.connectSocialMedia(socialMedia);
    dispatch(
      checkFbApprovedPermissions({
        accessToken: accessToken!,
        fbUserId: userID!,
        signedRequest: signedRequest!,
        onFetchFbPagesDone,
        isFbSystemUser: false,
        accountId: trillerInvite?.accountId,
      }),
    );
  };
  const loginToFb = useCheckFbLogin(onLoginSuccessForConnectFbAccount, 'rerequest');
  const loginToIg = useInstagramStandaloneLogin(trillerInvite?.accountId, trillerInvite?.botId, trillerInvite?.accessToken);
  const isFbLogin = trillerInvite?.platform === platforms.FACEBOOK;

  return (
    <>
      {isFbLogin ? (
        <button className={styles.facebookButton} type='button' onClick={() => loginToFb('facebook')}>
          {/* <FaFacebook /> */}
          <FacebookIcon style={{ width: '15px', height: '15px', marginRight: '5px' }} />
          Continue with Facebook
        </button>
      ) : (
        <button className={styles.facebookButton} type='button' onClick={() => loginToIg()}>
          {/* <FaFacebook /> */}
          <InstagramIcon style={{ width: '15px', height: '15px', marginRight: '5px' }} />
          Continue with Instagram
        </button>
      )}
    </>
  );
};

export default SocialLoginButton;

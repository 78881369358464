import React from 'react';
import { Layout } from 'antd';
import Header from './header';
import styles from './InvitationAccepted.module.scss';
import { TrillerInviteProvider, useTrillerInvite } from './hooks';
import ListFbPages from './components/ListFbPages';
import ButtonContainer from './components/ButtonContainer';

const InvitationAccepted: React.FC = () => {
  const { allFbPages, ...trillerInviteContextValues } = useTrillerInvite();
  const { Content, Footer } = Layout;

  return (
    <TrillerInviteProvider value={{ allFbPages, ...trillerInviteContextValues }}>
      <Layout className='app-container'>
        <Header />
        <Content className={styles.contentContainer}>
          <div className={styles.invitationContainer}>
            <div className={styles.invitationContent}>
              {/* Left Section - Text */}
              <div className={styles.invitationTextSection}>
                <h1 className={styles.inviteTitle}>Welcome to Community by Triller!</h1>
                <p className={styles.inviteText}>Triller has invited you to Kick Start your community.</p>
                <p className={styles.inviteText}>In order to start, you must set up credentials with Triller&apos;s Community and connect it to your Facebook business account.</p>

                {/* Facebook Login Button */}
                <ButtonContainer />
              </div>

              {/* Right Section - Image and Social Media Icons */}
              {Boolean(allFbPages?.currentUserPages?.length) ? (
                <ListFbPages />
              ) : (
                <div className={styles.invitationImageSection}>
                  <img
                    src='https://influencers.juliusworks.com/assets/shared/images/img_invitation_accepted.svg'
                    alt='Invitation Illustration'
                    className={styles.invitationImage}
                  />
                </div>
              )}
            </div>
          </div>
        </Content>

        {/* Footer */}
        <Footer className={styles.footer}>
          <p className={styles.footerText}>©2025 | TrillerCorp.com | All rights reserved</p>
        </Footer>
      </Layout>
    </TrillerInviteProvider>
  );
};

export default InvitationAccepted;

/* eslint-disable max-lines-per-function,max-lines */
import moment from 'moment';
import { BroadcastsUI } from '../../../common/entities/entities.types';
import { BroadcastsResponse, BroadcastTypeAPI, BroadcastTypeUI, MessageUsageResponse, CreateBroadcastApiRequest, BroadcastTypeAPIResponses } from '../types';
import {
  addShortURLTrackingInTextandRemoveSpecialChars,
  checkComplexTargetingAndResponse,
  detectURLandFindLength,
  getBroadcastTypeForAPI,
  getplatformOptions,
  getSegmentsChildConditions,
  getSendAsMMSValue,
  getTypeForBroadcast,
  isAutomaticBroadcast,
  migrateToNewStructure,
  removeURLTracking,
  appendText,
  replaceMentionsFromHtml,
  getMessageWithVarWithTwigSyntax,
  getWaTemplateResponse,
  getAdvancedSegmentConditionsWithoutIds,
  getUserVariableValues,
} from '../utils';
import { BroadcastStateData } from '../hooks';
import { broadcastScheduleConst, broadcastTypes, usNumbersCondition } from '../constants';
import { SegmentTags, Segments } from '../../segments/segment.types';
import { getTimeZone } from '../../../common/utils/time-zones';
import { getSegmentsCount } from '../../../utils/segment-counter';
import { getTwilioSmartEncodedString } from '../../../utils/segment-counter/twilio-smart-encoder';
import { platforms } from '../../../common/entities/entities.constants';
import { html2unicode } from '../../../common/utils/unicodeTransforms';
import { advancedTwigTemplateRegex, combinedRegexForVars, defaultTwigTemplate, variableRegex } from '../../../utils/regex';
import _, { cloneDeep, isEqual } from 'lodash';
import {
  areaCodeVariable,
  broadcastConditionOperator,
  childConditionsIfStatement,
  condition,
  conditionsOpForExists,
  operatorsForBroadcasts,
  operatorsForDistanceValueUnit,
  operatorsSupportingValueUnit,
} from 'src/common/components/tagging-gc-content/tagging-gc-content.constants';
import { convertAreaCodeValToRegex } from 'src/common/utils/conditionGroups';
import { checkBroadcastSelectedTargetConditions } from 'src/common/components/tagging-gc-content/utils';
import { GCChildCondition } from 'src/types/api-calls';

export function getBroadcastFrequency(repeat?: string) {
  switch (repeat) {
    case 'Every Hour':
      return 'Hourly';
    case 'Every Day':
      return 'Daily';
    case 'Every Week':
      return 'Weekly';
    case 'Every Month':
      return 'Monthly';
    case 'None':
      return 'None';
    default:
      return 'None';
  }
}

export function getBroadcastRepeatPeriod(repeat?: string) {
  switch (repeat) {
    case 'Hourly':
      return 'Every Hour';
    case 'Daily':
      return 'Every Day';
    case 'Weekly':
      return 'Every Week';
    case 'Monthly':
      return 'Every Month';
    case 'None':
    default:
      return undefined;
  }
}

export const broadcastsToUI = (response: BroadcastsResponse, timezone: string, enableDefaultUSTargeting?: boolean): BroadcastsUI => {
  const toUI: BroadcastsUI = {};
  timezone = getTimeZone(timezone);
  response.forEach((element: BroadcastTypeAPI) => {
    if (!element || element?.jobType === broadcastTypes.ADMIN || element?.jobType === broadcastTypes.SEGMENT_COUNTER || element?.jobType === broadcastTypes.SUBSCRIPTION_MISSING)
      return;

    const scheduledBotTime = moment.utc(element.date + ' ' + element.time, 'YYYY-MM-DD HH:mm').tz(timezone);
    const scheduledUserTime = moment.utc(element.date + ' ' + element.time, 'YYYY-MM-DD HH:mm').utcOffset(scheduledBotTime.utcOffset(), true); //convert to accounttimezone date+time
    const broadcastDate = element.timeZone === 'bot' ? scheduledBotTime : scheduledUserTime;
    const actions = removeURLTracking(element.responses || []);
    let elementsUpdatedCondition: any = cloneDeep(element?.conditions);

    if (elementsUpdatedCondition?.childConditions) {
      elementsUpdatedCondition.childConditions = elementsUpdatedCondition.childConditions?.map((childCondition: condition) => {
        if (isEqual(childCondition, usNumbersCondition)) {
          return childCondition;
        }
        return {
          ...{
            ...childCondition,
            logicalOperator: childCondition.logicalOperator,
            ifStatements: childCondition?.ifStatements?.map((ifStatement: childConditionsIfStatement) => {
              const broadcastConditionExistsNonExists =
                checkBroadcastSelectedTargetConditions(ifStatement?.variable?.macro) &&
                conditionsOpForExists?.includes(ifStatement?.condition) &&
                (ifStatement?.condition === conditionsOpForExists[1] ? broadcastConditionOperator.BROADCAST_NOT_RECEIVED : broadcastConditionOperator.BROADCAST_RECEIVED);
              delete ifStatement.id;
              return {
                ...ifStatement,
                ...(broadcastConditionExistsNonExists ? { condition: broadcastConditionExistsNonExists } : {}),
              };
            }),
            childConditions: childCondition.childConditions,
          },
        };
      });
    }

    if (enableDefaultUSTargeting && elementsUpdatedCondition?.logicalOperator !== 'or') {
      const currentChildCWithoutUS: any[] = elementsUpdatedCondition?.childConditions?.filter((childC: any) => !isEqual(childC, usNumbersCondition)) || [];
      elementsUpdatedCondition = {
        ...elementsUpdatedCondition,
        childConditions: [...currentChildCWithoutUS, usNumbersCondition],
        logicalOperator: elementsUpdatedCondition?.logicalOperator || 'and',
      };
    }

    const {
      isComplexTargeting: hasComplexTargeting,
      isComplexResponse: hasComplexResponse,
      isOldStructureSupportedByACM,
      hasAdvancedSegments,
      hasDefaultUsTargetingCondition,
      hasLocalVars,
      preventMessageEditWithWarning,
      hasCustomConditions,
      // isComplexBroadcastWarning,
    } = checkComplexTargetingAndResponse({ ...element, conditions: elementsUpdatedCondition }, enableDefaultUSTargeting);

    const UIformatObj: any = {
      hasCustomConditions,
      broadcastName: element.name,
      id: element.id,
      broadcastNodeId: element.broadcastNodeId,
      paused: element.paused,
      scheduleType: element.type,
      type: element?.jobType && getTypeForBroadcast(element.jobType),
      jobType: element?.jobType,
      // repeat: getBroadcastFrequency(obj.repeat),
      scheduledDateAndTime: element && isAutomaticBroadcast(element) ? 'Automatic' : broadcastDate.format(),
      draft: element.draft,
      subscriptionId: element.subscriptionId,
      repeat: element.repeat && getBroadcastFrequency(element.repeat),
      description: element.description,
      date: element.date,
      time: element.time,
      createdBy: element.createdBy,
      createdDate: element.createdDate,
      botId: element.botId,
      timeZone: element.timeZone,
      notificationLabels: element.notificationLabels,
      // responses: modifyTextInResponse(element.responses, appendTextValue),
      responses: element.responses,
      actions, // this also set click tracking url flag i.e. removeURLTracking
      isArchived: element.isDeleted || false,
      lastUpdatedDate: element.lastUpdatedDate,
      conditions: isOldStructureSupportedByACM ? migrateToNewStructure(elementsUpdatedCondition) : elementsUpdatedCondition,
      hasComplexTargetingNResponse: hasComplexTargeting || hasComplexResponse,
      ...(hasComplexTargeting || hasComplexResponse ? { hasComplexTargeting, hasComplexResponse } : {}),
      ...(hasAdvancedSegments ? { hasAdvancedSegments } : {}),
      ...(hasLocalVars ? { hasLocalVars } : {}),
      ...(preventMessageEditWithWarning ? { preventMessageEditWithWarning } : {}),
      ...(hasDefaultUsTargetingCondition ? { hasDefaultUsTargetingCondition } : {}),
      enableClickTracking: element?.urlClickTracking !== undefined ? element.urlClickTracking : actions.length ? actions[0].enableClickTracking : false,
      refcodes: element?.refcodes || [],
      excludeOptoutText: element.excludeOptoutText,
      sendAsMMS: getSendAsMMSValue(element),
      ...(element?.captureUserInput?.goToModules ? { captureUserInput: element.captureUserInput } : {}),
      setVariables: element?.setVariables,
      nodes: element.nodes && element.nodes?.length > 0 ? element?.nodes : [],
      testBroadcastInfo: element.testBroadcastInfo,
      updatedBy: element.updatedBy,
      isBroadcastMMS: element.isMMS,
      clonedFrom: element?.clonedFrom,
    };
    let dateObj;
    if (element.date && element.time) {
      if (element['timeZone'] === 'bot') {
        dateObj = moment.utc(element.date + ' ' + element.time, 'YYYY-MM-DD HH:mm').tz(timezone);
      } else {
        dateObj = moment(element.date + ' ' + element.time);
      }
    } else {
      dateObj = moment();
    }
    UIformatObj['startDate'] = dateObj.valueOf(); //millisecond epoch
    if (element.subscriptionIdsThatMustBeMissing) {
      UIformatObj['subscriptionIdsThatMustBeMissing'] = element.subscriptionIdsThatMustBeMissing;
    }
    if (element.id) {
      toUI[element.id] = UIformatObj;
    }
  });
  return toUI;
};
export const messageUsageToUI = (response: MessageUsageResponse, mmsSegmentFactor?: number) => {
  const usageArray: any = [];
  let carrierFeesCount = 0;
  response.usage.forEach((smsUsage: MessageUsageResponse['usage'][0]) => {
    const typeOriginal = smsUsage.type;
    const type = typeOriginal && typeOriginal.split('-')[0];
    const subType = typeOriginal && typeOriginal.split('-')[1];
    if (subType === 'segments') {
      let inBound = Number(smsUsage.in);
      let out = Number(smsUsage.out);
      if (type === 'mms' && mmsSegmentFactor) {
        inBound = inBound * mmsSegmentFactor;
        out = out * mmsSegmentFactor;
      }
      const total = inBound + out;
      total &&
        usageArray.push({
          type: type && type.toUpperCase(),
          inBound,
          out,
          total,
        });
      carrierFeesCount += Number(smsUsage.carrierFees);
    }
  });
  return { usageArray, carrierFeesCount };
};

export const createBroadcastUiToApi = async (
  broadcastData: BroadcastStateData,
  botId: string,
  timeZone: string,
  appendTextValue: string,
  timeZoneType: string,
  segments?: Segments,
  segmentTags?: SegmentTags,
  currentBot?: any,
  isCliqzTypeBotConfig?: boolean,
  enableDefaultGlobalTargeting?: boolean,
  platform?: string,
): Promise<any> => {
  const {
    currentName: name,
    currentMessage,
    broadcastType,
    enableClickTracking,
    mediaUrl,
    selectedSegmentIds,
    scheduleType,
    startDate,
    sendAsMMS,
    disableSendAsMms,
    excludeOptoutText,
    selectedWaTemplate,
    advancedSegmentConditions,
    userVariableComponents,
    captureUserInput,
  } = broadcastData;

  const { setVariables: setUserVariablesComponents } = getUserVariableValues(userVariableComponents);
  timeZone = getTimeZone(timeZone);
  const responses: [any] = [
    {
      type: mediaUrl ? 'mms' : 'sms',
      // enableClickTracking: true,
    },
  ];
  const isSMSPlatform = platform === platforms.TWILIO_SMS;
  const message = isSMSPlatform ? await html2unicode(replaceMentionsFromHtml(currentMessage)) : currentMessage;
  if (message) {
    const messageToSend = appendText(message, isSMSPlatform && excludeOptoutText, appendTextValue);
    const messageWithShortUrl = addShortURLTrackingInTextandRemoveSpecialChars(messageToSend, enableClickTracking);
    const { isTwig, messageWithVarInTwigSyntax } = getMessageWithVarWithTwigSyntax(messageWithShortUrl);

    responses[0].components = [
      {
        type: 'text',
        text: messageWithVarInTwigSyntax,
        platformOptions: getplatformOptions(mediaUrl || '', sendAsMMS || false, disableSendAsMms),
        ...(isTwig && { templateEngine: 'twig' }),
      },
    ];
  }
  if (mediaUrl) {
    responses[0].components = [
      {
        url: mediaUrl,
        type: 'image',
      },
      ...responses[0].components,
    ];
  }
  let customDomain: any;
  if (currentBot) {
    customDomain = currentBot.config && currentBot.config.shortUrlConfig && currentBot.config.shortUrlConfig.customDomain;
  }
  const messageToSend = appendText(message, isSMSPlatform && excludeOptoutText, appendTextValue);
  const transformedMessage = transformMessageForSegmentCount(messageToSend, enableClickTracking, customDomain).message;
  const numberOfMsgs = sendAsMMS || mediaUrl?.length ? 1 : getSegmentsCount(transformedMessage || '').numberOfMsgs;
  const toApi: CreateBroadcastApiRequest = {
    name,
    botId,
    date: moment.tz(timeZone || '').format('YYYY-MM-DD'),
    description: '',
    draft: true,
    jobType: broadcastType,
    paused: true,
    repeat: 'None',
    responses,
    time: moment.tz(timeZone || '').format('HH:mm'),
    timeZone: timeZoneType,
    type: scheduleType,
    subscriptionId: broadcastType === broadcastTypes.SUBSCRIPTION ? 'ALL' : undefined,
    urlClickTracking: enableClickTracking,
    excludeOptoutText,
    // setVariables: setUserVariablesComponents,
    ...(userVariableComponents !== undefined ? { setVariables: setUserVariablesComponents } : {}), //captureUserInput
    ...(captureUserInput?.goToModules ? { captureUserInput } : {}),
  };
  // const childConditionsWthoutSegmentId = toApi?.conditions?.childConditions?.filter((childC?: any) => childC?.segmentId === undefined) || [];
  const childConditionsWthoutSegmentId =
    toApi?.conditions?.childConditions?.filter((childC?: any) =>
      advancedSegmentConditions || !isCliqzTypeBotConfig || enableDefaultGlobalTargeting
        ? childC?.segmentId === undefined
        : childC?.segmentId === undefined && !_.isEqual(usNumbersCondition, childC),
    ) || [];
  const childConditions: any = advancedSegmentConditions
    ? getAdvancedSegmentConditionsWithoutIds(advancedSegmentConditions, childConditionsWthoutSegmentId)
    : getSegmentsChildConditions(selectedSegmentIds, segments!, segmentTags!, isCliqzTypeBotConfig, enableDefaultGlobalTargeting);
  toApi.conditions = {
    logicalOperator: advancedSegmentConditions?.logicalOperator || 'and',
    childConditions,
  };

  if (scheduleType === broadcastScheduleConst.LATER.value) {
    let utcDate = startDate;
    if (toApi['timeZone'] === 'bot') {
      const userDate = moment(startDate).format('YYYY-MM-DD HH:mm');
      utcDate = moment.tz(userDate, timeZone).utc();
    }
    toApi['date'] = utcDate?.format('YYYY-MM-DD')!;
    toApi['time'] = utcDate?.format('HH:mm')!;
  }
  if (numberOfMsgs) {
    toApi.isMMS = sendAsMMS || mediaUrl ? true : false;
    toApi.numberOfMessages = numberOfMsgs;
  }
  if (selectedWaTemplate?.id) {
    const waResponses = getWaTemplateResponse(selectedWaTemplate);
    waResponses && (toApi.responses = waResponses);
  }
  return toApi;
};
export const cloneBroadcastUiToApi = async (
  targetBroadcast: BroadcastTypeUI,
  updatedBroadcastData: BroadcastStateData,
  botId: string,
  timeZone: string,
  appendTextValue: string,
  currentBot?: any,
  platform?: string,
): Promise<any> => {
  const {
    currentName: name,
    currentMessage,
    broadcastType,
    enableClickTracking,
    mediaUrl,
    sendAsMMS,
    disableSendAsMms,
    excludeOptoutText,
    selectedWaTemplate,
    userVariableComponents,
  } = updatedBroadcastData;
  timeZone = getTimeZone(timeZone);
  const responses: any[] = targetBroadcast.responses;
  const { setVariables: setUserVariablesComponents } = getUserVariableValues(userVariableComponents, targetBroadcast);

  const isSMSPlatform = platform === platforms.TWILIO_SMS;
  const message = isSMSPlatform ? await html2unicode(replaceMentionsFromHtml(currentMessage)) : currentMessage;
  if (message) {
    const messageToSend = appendText(message, isSMSPlatform && excludeOptoutText, appendTextValue);
    const messageWithShortUrl = addShortURLTrackingInTextandRemoveSpecialChars(messageToSend, enableClickTracking);
    const { isTwig, messageWithVarInTwigSyntax } = getMessageWithVarWithTwigSyntax(messageWithShortUrl);

    responses[0].components = responses[0].components.length
      ? responses[0].components.map((component: any) => {
          if (component.type === 'text') {
            return {
              type: 'text',
              text: messageWithVarInTwigSyntax,
              platformOptions: getplatformOptions(mediaUrl || '', sendAsMMS || false, disableSendAsMms),
              ...(isTwig && { templateEngine: 'twig' }),
              buttons: component.buttons || [],
              quickReplies: component.quickReplies || [],
            };
          } else if (component.type === 'storeUserVariable') {
            const newComponent = { ...component };
            delete newComponent.value;
            delete newComponent.variable;
            delete newComponent.storeVariable;
            return newComponent;
          }
          return component;
        })
      : [
          {
            type: 'text',
            text: messageWithVarInTwigSyntax,
            platformOptions: getplatformOptions(mediaUrl || '', sendAsMMS || false, disableSendAsMms),
            ...(isTwig && { templateEngine: 'twig' }),
          },
        ];
  }
  if (mediaUrl) {
    responses[0].components = [
      {
        url: mediaUrl,
        type: 'image',
      },
      ...(responses[0]?.components?.filter((component?: any) => component?.type !== 'image') || []),
    ];
  } else if (!mediaUrl && isSMSPlatform) {
    const response = [...(responses[0]?.components?.filter((component?: any) => component?.type !== 'image') || [])];
    if (response.length) {
      responses[0].components = response;
    }
  }
  let customDomain: any;
  if (currentBot) {
    customDomain = currentBot.config && currentBot.config.shortUrlConfig && currentBot.config.shortUrlConfig.customDomain;
  }
  const messageToSend = appendText(message, isSMSPlatform && excludeOptoutText, appendTextValue);
  const transformedMessage = transformMessageForSegmentCount(messageToSend, enableClickTracking, customDomain).message;
  const numberOfMsgs = sendAsMMS || mediaUrl?.length ? 1 : getSegmentsCount(transformedMessage || '').numberOfMsgs;
  const clonedFromId = targetBroadcast.id.replace('_clone', '');
  const clonedFrom = `${targetBroadcast.broadcastName} (${clonedFromId})`;
  const toApi: any = {
    name,
    botId,
    date: moment
      .tz(timeZone || '')
      .add(2, 'minutes')
      .utc()
      .format('YYYY-MM-DD'),
    description: targetBroadcast.description || '',
    draft: true,
    jobType: broadcastType,
    paused: true,
    repeat: getBroadcastRepeatPeriod(targetBroadcast?.repeat),
    responses: responses.map((response: any) => {
      response.type !== 'module' && delete response?.id;
      delete response.enableClickTracking;
      return response;
    }),
    time: moment
      .tz(timeZone || '')
      .add(2, 'minutes')
      .utc()
      .format('HH:mm'),
    timeZone: targetBroadcast.timeZone,
    type: targetBroadcast.scheduleType || broadcastScheduleConst.NOW.value,
    subscriptionId: targetBroadcast.subscriptionId,
    urlClickTracking: enableClickTracking,
    excludeOptoutText,
    conditions: targetBroadcast.conditions as any,
    notificationLabels: targetBroadcast.notificationLabels as any,
    subscriptionIdsThatMustBeMissing: targetBroadcast.subscriptionIdsThatMustBeMissing as any,
    clonedFrom,
    // setVariables: setUserVariablesComponents,
    ...(userVariableComponents !== undefined ? { setVariables: setUserVariablesComponents } : {}), //captureUserInput
    ...(targetBroadcast?.captureUserInput?.goToModules ? { captureUserInput: targetBroadcast?.captureUserInput } : {}),
  };

  if (numberOfMsgs && isSMSPlatform && currentMessage) {
    toApi.isMMS = sendAsMMS || mediaUrl ? true : false;
    toApi.numberOfMessages = numberOfMsgs;
  }
  if (selectedWaTemplate?.id) {
    const waResponses = getWaTemplateResponse(selectedWaTemplate);
    if (waResponses?.[0]) {
      toApi.responses[0] = waResponses[0];
    }
  }
  return toApi;
};
export const transformerResponsesType = (broadcastResponses: BroadcastTypeAPIResponses[]) => {
  const data: BroadcastTypeAPIResponses[] = [];
  broadcastResponses.map((item: BroadcastTypeAPIResponses) =>
    data.push({
      ...item,
      enableClickTracking: undefined,
    }),
  );
  return data;
};

const processAdvancedCustomConditions = (customConditions: any): any => {
  if (!customConditions || !Array.isArray(customConditions.childConditions)) {
    return {
      logicalOperator: customConditions?.logicalOperator || 'and',
      childConditions: [],
    };
  }

  return {
    logicalOperator: customConditions.logicalOperator || 'and',
    childConditions: customConditions.childConditions.map((childCondition: any) => {
      const transformedCondition = {
        logicalOperator: childCondition.logicalOperator || 'and',
        ifStatements: childCondition.ifStatements?.map((ifStatement: any) => {
          const updatedIfStatement = { ...ifStatement };
          const hasDistanceOperators = operatorsForDistanceValueUnit.includes(ifStatement.condition);
          updatedIfStatement.valueUnit = operatorsSupportingValueUnit.includes(ifStatement.condition) || hasDistanceOperators ? ifStatement.valueUnit : undefined;
          if (!hasDistanceOperators && updatedIfStatement.metadata?.distanceFrom) {
            const metadata = { ...updatedIfStatement.metadata };
            delete metadata.distanceFrom;
            updatedIfStatement.metadata = metadata;
          }
          if (customConditions && updatedIfStatement.variable?.name === areaCodeVariable.name) {
            updatedIfStatement.value = convertAreaCodeValToRegex(updatedIfStatement.value?.trim())?.regexPattern;
          }
          if (operatorsForBroadcasts.includes(ifStatement.condition)) {
            updatedIfStatement.condition = ifStatement.condition === broadcastConditionOperator.BROADCAST_NOT_RECEIVED ? conditionsOpForExists[1] : conditionsOpForExists[0];
          }
          delete updatedIfStatement.id;
          return updatedIfStatement;
        }),
        childConditions: childCondition.childConditions ? processAdvancedCustomConditions(childCondition).childConditions : undefined,
      };

      return transformedCondition;
    }),
  };
};

const filterConditionsBySegmentId = (conditions: GCChildCondition[]): any[] => {
  return conditions?.filter((condition: GCChildCondition) => condition.childConditions?.some((childCondition: GCChildCondition) => childCondition.segmentId));
};
export const updateBroadcastUiToApi = async (
  broadcastData: BroadcastStateData,
  broadcast: BroadcastTypeUI,
  appendTextValue: string,
  timeZoneType: string,
  segments?: Segments,
  segmentTags?: SegmentTags,
  timezone?: string,
  currentBot?: any,
  isCliqzTypeBotConfig?: boolean,
  enableDefaultGlobalTargeting?: boolean,
  isFbInstaPlatform?: boolean | undefined | string,
  platform?: string,
) => {
  const {
    currentName: name,
    currentMessage,
    enableClickTracking,
    mediaUrl,
    selectedSegmentIds,
    scheduleType,
    startDate,
    sendAsMMS,
    disableSendAsMms,
    excludeOptoutText,
    selectedWaTemplate,
    advancedSegmentConditions,
    advancedCustomConditions,
    userVariableComponents,
  } = broadcastData;
  const { setVariables: setUserVariablesComponents } = getUserVariableValues(userVariableComponents, broadcast);
  const setVariables = userVariableComponents !== undefined ? { setVariables: setUserVariablesComponents } : {};

  timezone = getTimeZone(timezone || '');
  const responses: [any] = [
    {
      type: mediaUrl ? 'mms' : 'sms',
    },
  ];
  const isSMSPlatform = platform === platforms.TWILIO_SMS;
  const message = isSMSPlatform ? await html2unicode(replaceMentionsFromHtml(currentMessage)) : currentMessage;
  if (message) {
    const messageToSend = appendText(message, isSMSPlatform && excludeOptoutText, appendTextValue);
    const messageWithShortUrl = addShortURLTrackingInTextandRemoveSpecialChars(messageToSend, enableClickTracking);
    const { isTwig, messageWithVarInTwigSyntax } = getMessageWithVarWithTwigSyntax(messageWithShortUrl);

    responses[0].components = [
      {
        type: 'text',
        text: messageWithVarInTwigSyntax,
        platformOptions: getplatformOptions(mediaUrl || '', sendAsMMS || false, disableSendAsMms),
        ...(isTwig && { templateEngine: 'twig' }),
      },
    ];
  }
  if (mediaUrl) {
    responses[0].components = [
      {
        url: mediaUrl,
        type: 'image',
      },
      ...responses[0].components,
    ];
  }
  const {
    id,
    botId,
    description,
    draft,
    paused,
    type,
    lastUpdatedDate,
    conditions,
    hasComplexTargetingNResponse,
    hasComplexTargeting,
    responses: broadcastResponses,
    hasLocalVars,
    captureUserInput,
  } = broadcast;

  let customDomain: any;
  if (currentBot) {
    customDomain = currentBot.config && currentBot.config.shortUrlConfig && currentBot.config.shortUrlConfig.customDomain;
  }
  const messageToSend = appendText(message, isSMSPlatform && excludeOptoutText, appendTextValue);
  const transformedMessage = transformMessageForSegmentCount(messageToSend, enableClickTracking, customDomain).message;
  const numberOfMsgs = sendAsMMS || mediaUrl?.length ? 1 : getSegmentsCount(transformedMessage || '').numberOfMsgs;

  const toApi: any = {
    id,
    name,
    botId,
    description,
    draft,
    jobType: getBroadcastTypeForAPI(type),
    paused,
    repeat: getBroadcastRepeatPeriod(broadcast?.repeat),
    responses: (!currentMessage && isSMSPlatform) || isFbInstaPlatform ? transformerResponsesType(broadcastResponses) : responses,
    timeZone: timeZoneType,
    type: scheduleType?.toLowerCase(),
    lastUpdatedDate,
    conditions,
    urlClickTracking: enableClickTracking,
    excludeOptoutText,
    // setVariables: setUserVariablesComponents,
    ...setVariables,
    ...(captureUserInput?.goToModules ? { captureUserInput } : {}),
  };
  if (scheduleType === broadcastScheduleConst.LATER.value) {
    let utcDate = startDate;
    if (toApi['timeZone'] === 'bot') {
      const userDate = startDate?.format('YYYY-MM-DD HH:mm');
      utcDate = moment.tz(userDate, timezone!).utc();
    }
    toApi['date'] = utcDate?.format('YYYY-MM-DD')!;
    toApi['time'] = utcDate?.format('HH:mm')!;
  } else {
    const dateNow = startDate?.format('YYYY-MM-DD HH:mm');
    const utcNow = moment.tz(dateNow, timezone!).utc();

    toApi['date'] = utcNow?.format('YYYY-MM-DD')!;
    toApi['time'] = utcNow?.format('HH:mm')!;
    toApi['repeat'] = undefined;
  }

  if (hasComplexTargetingNResponse && hasComplexTargeting) {
    //no need to update to other values
    return {
      date: toApi.date,
      time: toApi.time,
      type: toApi.type,
      repeat: toApi.repeat,
      timeZone: timeZoneType,
      id,
      name,
      // setVariables: setUserVariablesComponents,
      ...setVariables,
      ...(captureUserInput?.goToModules ? { captureUserInput } : {}),
    };
  }
  const childConditionsWthoutSegmentId =
    toApi?.conditions?.childConditions?.filter((childC?: any) =>
      advancedSegmentConditions || !isCliqzTypeBotConfig || enableDefaultGlobalTargeting
        ? childC?.segmentId === undefined
        : childC?.segmentId === undefined && !_.isEqual(usNumbersCondition, childC),
    ) || [];
  const childConditions: any = advancedSegmentConditions
    ? getAdvancedSegmentConditionsWithoutIds(advancedSegmentConditions, childConditionsWthoutSegmentId)
    : getSegmentsChildConditions(selectedSegmentIds, segments!, segmentTags!, isCliqzTypeBotConfig, enableDefaultGlobalTargeting);

  const filteredChildConditionsContainsSegmentId = filterConditionsBySegmentId(childConditions);

  toApi.conditions = {
    logicalOperator:
      (advancedSegmentConditions || advancedCustomConditions
        ? advancedSegmentConditions?.logicalOperator || advancedCustomConditions?.logicalOperator
        : broadcast?.conditions?.logicalOperator) || 'and',
    childConditions: [
      ...(filteredChildConditionsContainsSegmentId || []),
      ...(advancedCustomConditions ? processAdvancedCustomConditions(advancedCustomConditions).childConditions : []),
    ],
  };

  if ((!hasComplexTargeting && hasComplexTargetingNResponse) || hasLocalVars) {
    // || hasUserVars
    //no need to update to other values
    const { date, time, type, repeat, conditions } = toApi;
    return {
      date,
      time,
      type,
      repeat,
      timeZone: timeZoneType,
      id,
      name,
      conditions,
      // setVariables: setUserVariablesComponents,
      ...setVariables,
      ...(captureUserInput?.goToModules ? { captureUserInput } : {}),
    };
  }
  if (numberOfMsgs) {
    toApi.isMMS = sendAsMMS || mediaUrl ? true : false;
    toApi.numberOfMessages = numberOfMsgs;
  }

  if (selectedWaTemplate?.id) {
    const waResponses = getWaTemplateResponse(selectedWaTemplate);
    waResponses && (toApi.responses = waResponses);
  }
  return toApi;
};

export const defaultValuesInAdvancedOptions = {
  enableClickTracking: true,
  scheduleType: 'immediate',
};

export const transformMessageForSegmentCount = (currentMessage: string, enableClickTracking: boolean, customDomain: any, appendSpaceBeforeUrl?: boolean) => {
  const { encodedString, replacedChars, specialCharsFound } = getTwilioSmartEncodedString(currentMessage);
  let message: string = encodedString || '';
  const { messageWithShortUrl, numberOfUrlsEndingWithPunctuationOrStartWithNonSpace, allUrls } = detectURLandFindLength(encodedString, customDomain, appendSpaceBeforeUrl);
  if (enableClickTracking) {
    message = messageWithShortUrl || '';
  }

  message = message + '#'.repeat(numberOfUrlsEndingWithPunctuationOrStartWithNonSpace); //we are putting extra spaces to separate url with punctuation
  combinedRegexForVars.lastIndex = 0;
  const variables: string[] = [];
  message = message.replaceAll(combinedRegexForVars, (variableName: string) => {
    variables.push(variableName);
    if (advancedTwigTemplateRegex.test(variableName)) {
      //we are assuming it is written in valid tig pattern
      return '#'.repeat(5);
    }

    //if default template Match

    const defaultTemplateMatch = variableName.match(defaultTwigTemplate);
    if (defaultTemplateMatch) {
      //converting to square notation
      const defaultValue = defaultTemplateMatch[2] || '';
      return '#'.repeat(defaultValue.length);
    }

    //if variable match
    const varMatch = variableName.match(variableRegex);

    if (varMatch) {
      //converting to square notation
      return '#'.repeat(5);
    }
    return variableName;
  });

  // const variables = message.match(/\{{(.*?)\}}/g);
  // variables?.forEach((match: string) => {
  //   message = message.replace(match, '#'.repeat(5)); //defaultVariableLength
  // });
  return { message, variables: variables.length ? variables : undefined, allUrls, replacedChars, specialCharsFound };
};

export const transformNotificationLabelApiToUi = (labels: { [key: string]: any }) => {
  return Object.entries(labels || {}).reduce((allLabels: { [key: string]: any }, [labelName, label]: any) => {
    return { ...allLabels, [labelName]: { ...label, label: labelName } };
  }, {} as { [key: string]: any });
};

import { get } from 'src/common/api-calls/api-client';
import { showErrorModal } from 'src/common/utils';
import { AsyncThunkDispatch, RootState, Thunk } from 'src/types';
import { TrillerInvite } from '../types';
import { setAuthToken } from 'src/pages/login/actions';
import { storeUserAccount } from 'src/common/entities/actions';
import { Account } from 'src/common/entities/entities.types';

export const getInviteDetails = (token: string): Thunk<TrillerInvite | null> => {
  return async (dispatch: AsyncThunkDispatch, _getState: () => RootState): Promise<TrillerInvite | null> => {
    // const state = getState();
    // const mockData = {
    //   //eslint-disable-next-line @typescript-eslint/naming-convention
    //   access_token:
    //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxMGE2NjcwYTlhYTQyNmRhMGIwZDc0YiIsImVtYWlsIjoiY
    // XNoaXNoQGFtcGxpZnkuYWkiLCJuYW1lIjoiQXNoaXNoIFBhdGVsIiwiaWF0IjoxNzM5MjU1MjMyLCJleHAiOjE3Mzk2ODcyMzJ9.3u_RAYZ9tklxlI8VxCInitE_yUR0zeuB2YOcUMXypQ0',
    //   accountId: '610a6659a9aa426da0b0d749',
    //   botId: '6128f73f1b94e738cfac41ae',
    //   userId: '',
    //   platform: 'instagram',
    // };
    const url = `triller/loginWithEncryptedData?token=${token}`;
    let response: { data: TrillerInvite; status: number } | null = null;
    try {
      response = await get(url, undefined, true);
      if (response?.data) {
        dispatch(setAuthToken({ authToken: response.data.accessToken, expiresIn: 3600, doNotStoreInLocalStorage: true }));
        dispatch(storeUserAccount({ id: response.data.accountId } as Account));
        return response?.data;
      }
      return null;
    } catch (error: any) {
      showErrorModal(error, dispatch, undefined, 'Invalid Invite', true);
    }

    // dispatch(setAuthToken({ authToken: mockData.access_token, expiresIn: 3600 }));
    // dispatch(storeUserAccount({ id: mockData.accountId } as Account));
    return null;
  };
};

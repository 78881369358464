import { Checkbox } from 'antd';
import React from 'react';
import { FbPage as FbPageType, SelectedPage } from '../../../fb-login/types';
import styles from '../deploy-page-list.module.scss';

type FbPageProps = {
  fbPage: FbPageType;
  selectedPages: { [key: string]: SelectedPage };
  handleCheckboxChange: (fbPage: FbPageType) => void;
  handleInstagramCheckboxChange: (fbPage: FbPageType, instagramAccountId: string) => void; //fbPageId
};

const PageItem = ({ fbPage, selectedPages, handleCheckboxChange }: FbPageProps) => {
  return (
    <li key={fbPage.id} className={selectedPages[fbPage.id]?.status ? styles.selectedFbPageContainer : styles.fbPageContainer}>
      <Checkbox onChange={() => handleCheckboxChange(fbPage)} checked={selectedPages[fbPage.id]?.status}>
        <img className={styles.fbPagePic} src={fbPage.imageUrl} alt={fbPage.name} />
        <span className={styles.fbPageName}>{fbPage.name}</span>
      </Checkbox>
    </li>
  );
};

export default PageItem;

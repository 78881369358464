import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { initializeAuthFromQueryParams } from '../../../common/utils/enable-as-iframe';
import { clearTempAuth, setAuthToken, setTempAuthDetails, storeUserDetails, storeUserPermissions, storeUserRoles } from '../actions';
import { Auth } from '../login.types';

const initializeAuthFromLocalStorage = () => {
  const auth: Auth = {
    user: null,
    permissions: null,
    authToken: null,
  };
  const authTokenTime = localStorage.getItem('authTokenExpiryTime');
  if (authTokenTime && new Date(authTokenTime) > new Date(Date.now())) {
    const user = localStorage.getItem('user');
    const permissions = localStorage.getItem('permissions');
    const authToken = localStorage.getItem('authToken');
    auth.user = user && JSON.parse(user);
    auth.permissions = permissions && JSON.parse(permissions);
    auth.authToken = authToken;
    return auth;
  }
  return null;
};

const localStorageAuth = initializeAuthFromQueryParams() || initializeAuthFromLocalStorage();

const initialState: Auth = {
  authToken: (localStorageAuth && localStorageAuth.authToken) || '',
  user: (localStorageAuth && localStorageAuth.user) || null,
  permissions: (localStorageAuth && localStorageAuth.permissions) || null,
};

/*eslint-disable-next-line max-lines-per-function*/
const loginReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(setAuthToken, (state: typeof initialState, action: ReturnType<typeof setAuthToken>) => {
      state.authToken = action.payload.authToken;
      const ts = new Date(new Date().getTime() + action.payload.expiresIn * 1000);
      if (!action.payload.doNotStoreInLocalStorage) {
        localStorage.setItem('authToken', action.payload.authToken);
        localStorage.setItem('authTokenExpiryTime', ts.toString());
      }
    })
    .addCase(storeUserDetails, (state: typeof initialState, action: ReturnType<typeof storeUserDetails>) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(state.user));
    })
    .addCase(storeUserRoles, (state: typeof initialState, action: ReturnType<typeof storeUserRoles>) => {
      if (state.user) {
        state.user.userRoles = action.payload;
        localStorage.setItem('user', JSON.stringify(state.user));
      }
    })
    .addCase(setTempAuthDetails, (state: typeof initialState, action: ReturnType<typeof setTempAuthDetails>) => {
      state.tempAuthDetails = action.payload;
    })
    .addCase(clearTempAuth, (state: typeof initialState, _action: ReturnType<typeof clearTempAuth>) => {
      state.tempAuthDetails = undefined;
    })
    .addCase(storeUserPermissions, (state: typeof initialState, action: ReturnType<typeof storeUserPermissions>) => {
      state.permissions = action.payload;
      localStorage.setItem('permissions', JSON.stringify(state.permissions));
    });
});

export default loginReducer;

import moment, { Moment } from 'moment-timezone';
import React, { Dispatch, SetStateAction } from 'react';
import { TemplateMessageError } from '../templates/templates.types';
import { DEFAULT_OPT_OUT_TEXT } from './config';
import { BroadcastTypeUI, SelectedSegments, UserCountUpdated } from './types';
import { GCConditions } from 'src/types/api-calls';

export type BroadcastPageContextType = {
  createMode: boolean;
  editMode: boolean;
  viewMode: boolean;
  cloneMode: boolean;
  broadcastId: string;
  selectedBroadcast: BroadcastTypeUI | null;
  isAccBannerVisible: boolean;
  usersCount: UserCountUpdated | null;
  onDone: (toTab?: string) => void;
  isFormValid: () => boolean;
  isNameValid: () => boolean;
  isMessageValid: () => TemplateMessageError;
  showInvalidFields: () => void;
  touched: { [key: string]: boolean };
  setTouched: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  currentName: string;
  setCurrentName: Dispatch<SetStateAction<string>>;
  currentMessage: string;
  setCurrentMessage: Dispatch<SetStateAction<string>>;
  broadcastType: string;
  setBroadcastType: Dispatch<SetStateAction<string>>;
  labels: string[] | string;
  setLabels: Dispatch<SetStateAction<string[] | string>>;
  isRepeatBroadcast: string;
  setIsRepeatBroadcast: Dispatch<SetStateAction<string>>;
  responseType: string;
  setResponseType: Dispatch<SetStateAction<string>>;
  modules: any[];
  setModules: Dispatch<SetStateAction<any[]>>;
  enableClickTracking: boolean;
  setEnableClickTracking: Dispatch<SetStateAction<boolean>>;
  scheduleType: string;
  setScheduleType: Dispatch<SetStateAction<string>>;
  startDate: Moment;
  setStartDate: Dispatch<SetStateAction<Moment>>;
  showScheduleDateWarning: boolean;
  setShowScheduleDateWarning: Dispatch<SetStateAction<boolean>>;
  selectedSegmentIds: SelectedSegments;
  setSelectedSegmentIds: React.Dispatch<React.SetStateAction<SelectedSegments>>;
  advancedSegmentConditions?: any;
  setAdvancedSegmentConditions?: Function;
  isvalidAdvancedCustomConditions?: boolean;
  advancedCustomConditions?: GCConditions;
  setAdvancedCustomConditions?: Function;
  isvalidAdvancedSegments?: boolean;
  sendAsMMS: boolean;
  setSendAsMMS: Dispatch<SetStateAction<boolean>>;
  userVariableComponents?: any[];
  setUserVariableComponents?: Dispatch<SetStateAction<any>>;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  auditLogs: any[];
  setAuditLogs: Dispatch<SetStateAction<any>>;
  excludeOptoutText: boolean;
  setOptoutTextVisiblity: Dispatch<SetStateAction<boolean>>;
  isSegmentValid: boolean;
  setIsSegmentValid: Dispatch<SetStateAction<boolean>>;
  appendTextValue: string;
  previousOptOutText: string;
  timeZoneType: string;
  setTimeZoneType: Dispatch<SetStateAction<string>>;
  waTemplates: any[];
  setWaTemplates?: Dispatch<SetStateAction<any[]>>;
  selectedWaTemplate?: any;
  setSelectedWaTemplate?: Dispatch<SetStateAction<string>>;
  isValidWaTemplate?: boolean;
  resetBroadcast: boolean;
  setResetBroadcast: Dispatch<SetStateAction<boolean>>;
  hasMergeTagConverted: boolean;
  setHasMergeTagConverted: Dispatch<SetStateAction<boolean>>;
  clonedFrom?: string;
};

const mockFn = () => {};

export const BroadcastPageContext = React.createContext<BroadcastPageContextType>({
  createMode: true,
  editMode: false,
  viewMode: false,
  cloneMode: false,
  broadcastId: '',
  selectedBroadcast: {} as BroadcastTypeUI,
  isAccBannerVisible: false,
  usersCount: null,
  onDone: mockFn,
  isFormValid: () => false,
  isNameValid: () => false,
  isMessageValid: () => ({ isValid: false, errorMessage: '' }),
  showInvalidFields: mockFn,
  touched: { name: false },
  setTouched: mockFn,
  currentName: '',
  setCurrentName: mockFn,
  currentMessage: '',
  setCurrentMessage: mockFn,
  broadcastType: '',
  setBroadcastType: mockFn,
  labels: '',
  setLabels: mockFn,
  isRepeatBroadcast: '',
  setIsRepeatBroadcast: mockFn,
  responseType: '',
  setResponseType: mockFn,
  modules: [],
  setModules: mockFn,
  enableClickTracking: false,
  setEnableClickTracking: mockFn,
  scheduleType: '',
  setScheduleType: mockFn,
  startDate: moment.tz(),
  setStartDate: mockFn,
  showScheduleDateWarning: false,
  setShowScheduleDateWarning: mockFn,
  selectedSegmentIds: { included: ['all'], excluded: [] },
  setSelectedSegmentIds: () => {},
  sendAsMMS: false,
  setSendAsMMS: mockFn,
  userVariableComponents: [],
  setUserVariableComponents: mockFn,
  currentStep: 0,
  setCurrentStep: mockFn,
  auditLogs: [],
  setAuditLogs: mockFn,
  excludeOptoutText: true,
  setOptoutTextVisiblity: mockFn,
  isSegmentValid: false,
  setIsSegmentValid: mockFn,
  appendTextValue: DEFAULT_OPT_OUT_TEXT,
  previousOptOutText: DEFAULT_OPT_OUT_TEXT,
  timeZoneType: 'bot',
  setTimeZoneType: mockFn,
  waTemplates: [],
  setWaTemplates: mockFn,
  selectedWaTemplate: '',
  setSelectedWaTemplate: mockFn,
  isValidWaTemplate: false,
  resetBroadcast: true,
  setResetBroadcast: mockFn,
  hasMergeTagConverted: false,
  setHasMergeTagConverted: mockFn,
  clonedFrom: '',
});

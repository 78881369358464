/* eslint-disable max-lines */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { platforms } from '../common/entities/entities.constants';
import { Account, PlatformBot, PrivateReplyTags, UserAccount } from '../common/entities/entities.types';
import { initializePageFilter, setApplicableTabsSubTabs, setFilter, setPageUIState } from '../common/page-ui-state/actions';
import { FBConfig } from '../config';
import { checkFbApprovedPermissions, toggleFbWarningModalDisplay, setFbPageResponse } from '../pages/fb-login/actions';
import { AmplifyError, ErrorType, GenericObject, History, IntervalFunction, RootState } from '../types';
import { deployAmplifyLiteBot, deployBot, deployFbPage, fetchInstagramPages, unlinkPage } from '../pages/fb-login/actions/async-actions';
import { Dispatch } from 'redux';
import { FBDeployPage, FbPageResponse, InstagramPageItem, SelectedPage } from '../pages/fb-login/types';
import { logger } from '../common/utils/logger';
import { fetchCustomLabelsForABot, getBotsAndPlatformBots, getBotSettings, getUserAccounts } from '../common/entities/actions/async-actions';
import { handleSWUpdateOnRouteChange } from '../sw-config';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { deleteFBCookie, getFiltersFromUrl } from '../utils';
import { permissionEntities, permissionType } from '../common/role-level-permission/permission.constants';
import { useUserPermissions } from '../common/role-level-permission/hooks/permission';
import { defaultFiltersForPages } from '../pages/dashboard/components/comments-viewer/comments-viewer.constants';
import { pageFilterKeys, pageKeysForFilter } from '../pages/dashboard/constants';
import { setQueryParamsFromObject } from '../common/utils/persist-time-filter';
import { AmpLitePages } from '../pages/common/sidebar/constants';
import { fetchBotMacroDetailsForNonCliqzBots, fetchBotProfileDetails } from '../pages/settings/actions/async-actions';
import { useAccount } from '../pages/common/header/hooks';
import { botConfigTypeCliqz } from '../pages/settings/constant';
import { getRouteParams } from '../common/page-ui-state/utils';
import { logOut } from '../pages/login/actions';
import { getTimeZone } from '../common/utils/time-zones';
import { registerAmplifyAnalytics } from '../analytics/utils';
import { IsExternalUser } from '../common/utils/external-user';
import { getCurrentUserRoles } from '../pages/user-profile-pages/actions/async-actions';
import { getEquivalentUrlForPRO } from '../pages/broadcasts/utils';
import { getSidebar } from '../pages/common/sidebar/utils';
import { useIsAdminUser, useIsBotworxAdminUser, useIsCliqzTypeBotConfig } from '../pages/user-profile-pages/hooks';
import { useIsMobile } from '../mobile/utils';
import { updateLastLoggedIn } from '../pages/login/actions/async-actions';
import { getRouterTitle } from '../utils/path-to-title';
import { routeToPage } from '../constants';
import { LogActivityTimeLimit, UserActivityKey } from '../common/constants';
import { getApiEndPointForInstagramDeployment } from 'src/utils/fb-utils';

export const useLoadInitialAccountAndPage = () => {
  const dispatch = useDispatch();
  const isPlatformActionConfigFetched = useSelector((state: RootState) => state.platformActionConfig.platformActionConfigFetched);
  const accountId = useAccountId() || '';
  const isAccountsFetched = useSelector((state: RootState) => state.pageUIState.isAccountsFetched);
  const bot = useCurrentBot();
  const currentPlatformBot = useCurrentPlatformBotObj();
  const history = useHistory();
  const currentPlatformBotId = usePlatformBotId();

  useEffect(() => {
    if (bot && bot.type === botConfigTypeCliqz) {
      bot && dispatch(fetchBotProfileDetails(bot.id));
    } else if (bot && currentPlatformBot) {
      bot && dispatch(fetchBotMacroDetailsForNonCliqzBots(bot.id));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, bot, bot && bot.id]);
  useEffect(() => {
    if (!isAccountsFetched) {
      //|| !accountId
      // const onSuccess = (accountId?: string) => {
      //   // accountId && dispatch(getBotsAndPlatformBots(accountId, undefined, undefined, isPlatformActionConfigFetched));
      // };
      /*getBotsAndPlatformBots call goes either from app-layout useEffect or this useEffect below condition & currentUser's roles fetched after current user account fetch */
      dispatch(getUserAccounts(() => dispatch(getCurrentUserRoles()), '', history));
    }
    if (isPlatformActionConfigFetched) {
      dispatch(getBotsAndPlatformBots(accountId, undefined, currentPlatformBotId)); //keep the selected platform intact
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAccountsFetched, isPlatformActionConfigFetched, accountId]);
};
export const useRedirectToRoute = () => {
  const dispatch = useDispatch();
  const { handleAccountChange } = useAccount();

  return (accountId: string) => {
    dispatch(setApplicableTabsSubTabs([]));
    handleAccountChange(accountId);
  };
};
export const useServiceWorkerUpdate = () => {
  const location = useLocation();
  useEffect(() => {
    handleSWUpdateOnRouteChange();
  }, [location, location.pathname]);
};
export const usePlatformBotId = () => {
  const pageUIState = useSelector((state: RootState) => state?.pageUIState);
  return pageUIState.selectedPlatformBot;
};
export const useTimeZone = () => {
  const currentAccount: UserAccount | undefined = useSelector((state: RootState) => state?.entities.account);
  if (currentAccount) {
    return getTimeZone(currentAccount.timezone);
  }
  return '';
};
export const useAllNotifLabels = (versionId: string) => {
  const allNotificationLabels = useSelector((state: RootState) => versionId && state?.entities.versions?.[versionId]?.notificationLabels);
  return allNotificationLabels || [];
};

export const useVersionsModules = (versionId: string) => {
  const mappedModule = useSelector((state: RootState) => state?.entities.versions?.[versionId]?.modules);
  return mappedModule;
};

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: IntervalFunction, delay: number | null) => {
  const savedCallback = useRef<IntervalFunction | null>(null);

  useEffect(() => {
    if (delay === null) return;
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (delay === null) return;
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export const useIsInstagramPage = () => {
  const selectedPlatformBot = useSelector((state: RootState) => state.pageUIState.selectedPlatformBot)!;
  const platformBots = useSelector((state: RootState) => state.entities.platformBots)!;
  const isInstagramPage =
    selectedPlatformBot && platformBots[selectedPlatformBot] && platformBots[selectedPlatformBot].platformAsInSystemSettings === platforms.INSTAGRAM ? true : false;
  return isInstagramPage;
};
export const useFilterForPage = (pageName: string) => {
  const pageFilters = useFiltersForPages()?.[pageName];
  return pageFilters;
};

export const useFiltersForPages = () => {
  const pageFilters = useSelector((state: RootState) => state?.pageUIState.filters);
  return pageFilters;
};

export const usePageFiltersFromUrl = (pageName: string, filterKey?: string) => {
  const pageFilters = useFilterForPage(pageName);
  const filters = pageFilters && Object.entries(pageFilters).length ? getFiltersFromUrl() : {};
  return (filterKey ? filters[filterKey] : filters) || {};
};

export const useCheckIfInstagram = () => {
  const platformBots = useSelector((state: RootState) => state?.entities.platformBots);
  const platformBotId = usePlatformBotId() || '';
  if (platformBots) {
    const platformObj = platformBots[platformBotId];
    if (platformObj.platform === platforms.INSTAGRAM) {
      return true;
    }
  }
  return false;
};

export const useCurrentPlatformBotObj = () => {
  const currentPlatformBotId = usePlatformBotId();
  let platformBot: PlatformBot | undefined = undefined;
  const platformBots: { [key: string]: PlatformBot } | undefined = useSelector((state: RootState) => state?.entities.platformBots);

  if (currentPlatformBotId && platformBots) {
    platformBot = platformBots[currentPlatformBotId];
  }
  return platformBot;
};

export const useLinkFbPages = (onFbLoginSuccess?: () => void) => {
  const dispatch = useDispatch();
  const loginToFb = () => {
    handleFbLogin(dispatch, onFbLoginSuccess);
  };
  return loginToFb;
};

export const useUnlinkPage = () => {
  const dispatch = useDispatch();
  const checkLoginStatusToUnlinkPage = (platformBotId: string) => {
    (window as any).FB.getLoginStatus(function (response: any) {
      if (response.status === 'connected') {
        dispatch(unlinkPage(platformBotId));
      } else if (response.status === 'not_authorized') {
        dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
        // The user hasn't authorized your application.  They
        // must click the Login button, or you must call FB.login
        // in response to a user gesture, to launch a login dialog.
      } else {
        // getting status as 'unknown' when user logs out
        deleteFBCookie();
        logger.log('cookie Deleted after status unknown');
        dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
      }
    }, true); // true forces fb to get valid token everytime without caching it
  };
  return checkLoginStatusToUnlinkPage;
};

export const useCheckFbLogin = (onLoginSuccess: (accessToken: string, userID: string, signedRequest: string) => void, authType: string = 'reauthorize') => {
  const dispatch = useDispatch();
  const loginToFb = (socialMedia?: string) => {
    handleFbLogin(dispatch, onLoginSuccess, authType, socialMedia);
  };
  return loginToFb;
};

export const useInstagramStandaloneLogin = (accountId?: string, botId?: string, authToken?: string) => {
  const loginToInstagram = () => {
    if (accountId && botId && authToken) {
      window.location.href = getApiEndPointForInstagramDeployment(accountId, botId, authToken);
    }
  };

  return loginToInstagram;
};

// eslint-disable-next-line max-lines-per-function
const handleFbLogin = (
  dispatch: Dispatch<any>,
  onLoginSuccess?: (accessToken: string, userID: string, signedRequest: string, socialMedia: string) => void,
  authType: string = 'reauthorize',
  socialMedia: string = 'facebook',
) => {
  let fbLoginStatusSuccess = false;
  const requiredPerm = Object.keys(FBConfig.permission.required);
  const optionalPerm = Object.keys(FBConfig.permission.optional);
  const permissionScope = requiredPerm.concat(optionalPerm);
  const fbStatusCheckTimeout = setTimeout(function () {
    if (!fbLoginStatusSuccess) {
      const errorProps: AmplifyError = {
        title: 'Error',
        errorMessage: "Can't connect with Facebook Server. Please try after some time.",
        type: ErrorType.ERROR,
      };
      dispatch(setPageUIState({ key: 'error', value: errorProps }));
    }
  }, 60000);
  (window as any).FB.login(
    function (response: any) {
      fbLoginStatusSuccess = true;
      clearTimeout(fbStatusCheckTimeout);
      if (response.status === 'connected') {
        /* eslint-disable no-console */
        console.log('FB connected login');
        // Logged into your app and Facebook.
        const { accessToken, userID, signedRequest } = response.authResponse;
        const fbPageResponse: FbPageResponse = { accessToken, signedRequest, userID };
        dispatch(setFbPageResponse({ fbPageResponse }));
        if (onLoginSuccess) {
          onLoginSuccess(accessToken, userID, signedRequest, socialMedia);
        } else {
          console.log('Checking FB approved permissions');
          dispatch(
            checkFbApprovedPermissions({
              accessToken,
              fbUserId: userID,
              signedRequest,
            }),
          );
        }
      } else if (response.status === 'not_authorized') {
        // The person is logged into Facebook, but not authorized to your app.
        /* eslint-disable no-console */
        dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
        console.log('Please log into this app');
        /* eslint-enable no-console */
      } else {
        dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
        /* eslint-disable no-console */
        console.log('Please log into facebook');
        /* eslint-enable no-console */
      }
    },
    {
      /* eslint-disable @typescript-eslint/naming-convention */
      scope: permissionScope.toString(),
      return_scopes: true,
      auth_type: authType,
      /* eslint-enable @typescript-eslint/naming-convention */
    },
  );
};

export const useCampaignTags = () => {
  const campaignTags: PrivateReplyTags = useSelector((state: RootState) => state?.entities.privateReplyTags) || {};
  return campaignTags;
};
export const useCurrentAccountId = () => {
  const accountId = useSelector((state: RootState) => state.entities.account?.id);
  return accountId;
};

export const usePlatformBots = () => {
  const platformBots = useSelector((state: RootState) => state.entities.platformBots);
  return platformBots;
};
export const useCampaigns = () => {
  return useSelector((state: RootState) => state.entities.campaigns);
};

//eslint-disable-next-line max-lines-per-function
export const useDeployPages = (done?: () => void, instaOnlyFlow: boolean = false) => {
  const dispatch = useDispatch();
  const fbPageResponse = useSelector((state: RootState) => state.fbDeploy?.fbPageResponse);
  const instaAccessToken = useSelector((state: RootState) => state.fbDeploy?.instaAccessToken);

  //eslint-disable-next-line max-lines-per-function
  return (fbPageList: FBDeployPage[], templateSelected: string, isFbSystemUser: boolean, instaPageList?: false | InstagramPageItem[] | undefined): void => {
    (window as any).FB.getLoginStatus(function (response: any) {
      if (response.status === 'connected' && !instaOnlyFlow && !instaAccessToken) {
        logger.log('Inside deployPages : FB login successful');
        const { accessToken, userID, signedRequest } = response.authResponse;
        logger.log(fbPageList);
        for (const fbPage of fbPageList) {
          dispatch(deployFbPage({ pageDetails: fbPage, fbUserId: userID, accessToken, signedRequest, isFbSystemUser }, templateSelected));
        }
        done && done();
      } else if (response.status === 'not_authorized' && !instaOnlyFlow && !instaAccessToken) {
        dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
        // The user hasn't authorized your application.  They
        // must click the Login button, or you must call FB.login
        // in response to a user gesture, to launch a login dialog.
      } else {
        if (!!(instaOnlyFlow || instaAccessToken) && instaPageList) {
          logger.log(instaPageList);
          for (const instaPage of instaPageList) {
            dispatch(deployAmplifyLiteBot(instaPage, templateSelected, isFbSystemUser) || {});
          }
          done && done();
        } else {
          logger.log('Inside deployPages : FB login failed');
          deleteFBCookie();
          logger.log('cookie Deleted after status unknown');
          // getting status as 'unknown' when user logs out
          if (!fbPageResponse) {
            dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
          } else {
            logger.log(fbPageList);
            for (const fbPage of fbPageList) {
              dispatch(
                deployFbPage(
                  {
                    pageDetails: fbPage,
                    fbUserId: fbPageResponse?.userID,
                    accessToken: fbPageResponse?.accessToken,
                    signedRequest: fbPageResponse?.signedRequest,
                    isFbSystemUser,
                  },
                  templateSelected,
                ),
              );
            }
            done && done();
          }
        }
      }
    }, true); // true forces fb to get valid token everytime without caching it
  };
};

export const useDeployBot = (done?: () => void) => {
  const dispatch = useDispatch();

  //eslint-disable-next-line max-lines-per-function
  return (fbPageList: FBDeployPage[], isFbSystemUser: boolean, botId: string): void => {
    (window as any).FB.getLoginStatus(function (response: any) {
      if (response.status === 'connected') {
        logger.log('Inside deployPages : FB login successful');
        const { accessToken, userID, signedRequest } = response.authResponse;
        logger.log(fbPageList);
        for (const fbPage of fbPageList) {
          dispatch(deployBot({ pageDetails: fbPage, fbUserId: userID, accessToken, signedRequest, isFbSystemUser, botId, onDone: done }));
        }
      } else if (response.status === 'not_authorized') {
        dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
        // The user hasn't authorized your application.  They
        // must click the Login button, or you must call FB.login
        // in response to a user gesture, to launch a login dialog.
      } else {
      }
    }, true); // true forces fb to get valid token everytime without caching it
  };
};

export const useCheckLoginAndFetchInstagramPages = () => {
  const dispatch = useDispatch();
  const fbPageResponse = useSelector((state: RootState) => state.fbDeploy?.fbPageResponse);
  const redirectUri = `${encodeURIComponent(`${window.location.origin}/`)}`;
  return (selectedPages: { [key: string]: SelectedPage }, isFbSystemUser: boolean, onSuccess?: () => void, instaOnlyFlow: boolean = false): void => {
    if (!instaOnlyFlow) {
      (window as any).FB.getLoginStatus(function (response: any) {
        if (response.status === 'connected') {
          logger.log('Inside useCheckLoginAndFetchInstagramPages : FB login successful');
          const { accessToken } = response.authResponse;
          dispatch(
            fetchInstagramPages({
              isFbSystemUser,
              platformBotIds: Object.keys(selectedPages).filter((platformBotId: string) => selectedPages[platformBotId].status),
              fbAccessToken: accessToken,
              done: onSuccess,
              redirectUri,
            }),
          );
        } else if (response.status === 'not_authorized') {
          dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
          // The user hasn't authorized your application.  They
          // must click the Login button, or you must call FB.login
          // in response to a user gesture, to launch a login dialog.
        } else {
          logger.log('Inside useCheckLoginAndFetchInstagramPages : FB login failed');
          deleteFBCookie();
          logger.log('cookie Deleted after status unknown');
          // getting status as 'unknown' when user logs out
          if (!fbPageResponse) {
            dispatch(toggleFbWarningModalDisplay({ showFBLoginModal: true }));
          } else {
            dispatch(
              fetchInstagramPages({
                isFbSystemUser,
                platformBotIds: Object.keys(selectedPages).filter((platformBotId: string) => selectedPages[platformBotId].status),
                fbAccessToken: fbPageResponse?.accessToken,
                done: onSuccess,
                redirectUri,
              }),
            );
          }
        }
      }, true); // true forces fb to get valid token everytime without caching it
    } else {
      onSuccess && onSuccess();
    }
  };
};

export const useInstaAccessToken = () => {
  const instaToken = useSelector((state: RootState) => state?.fbDeploy?.instaAccessToken);
  return instaToken;
};

export const useCurrentUser = () => {
  const user = useSelector((state: RootState) => state.auth && state.auth.user);
  return user;
};
export const useAllUsers = () => {
  const user = useSelector((state: RootState) => state.auth && state.entities.users);
  return user;
};

export const useAccountId = () => {
  return useSelector((state: RootState) => state.entities.account?.id);
};

export const useIds = () => ({ accountId: useAccountId(), platformBotId: usePlatformBotId() });

export const useVariables = () => {
  const variables = useSelector((state: RootState) => state.entities.variables);
  const currentPlatformBotObj = useCurrentPlatformBotObj();
  const systemSettingsConfig: any = usePlatformActionConfig();
  const [filteredVars, setFilteredVars] = useState(variables);

  useEffect(() => {
    if (Object.keys(variables)?.length && currentPlatformBotObj && systemSettingsConfig) {
      const platformAsInSystemSettings = (currentPlatformBotObj && currentPlatformBotObj.platformAsInSystemSettings) || '';
      const { variableTypes } = systemSettingsConfig?.[platformAsInSystemSettings] || {};
      let varsFiltered: any = {};
      const sortedVars = Object.values(variables)?.sort(function (a: any, b: any) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      if (!variableTypes) {
        //if variableType is not defined, we are showing everything
        varsFiltered = { ...variables };
      } else {
        sortedVars?.forEach((variable: any) => {
          if (!variableTypes?.[variable.type]?.disabled) {
            const allowedVariableNames = variableTypes?.[variable.type]?.allowedVariableNames;
            if (allowedVariableNames) {
              //if variableType is not defined, we are showing every variable
              allowedVariableNames.includes(variable.name) && variable?.id && (varsFiltered[variable?.id] = variable);
            } else {
              variable?.id && (varsFiltered[variable?.id] = variable);
            }
          }
        });
      }

      setFilteredVars(varsFiltered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables, currentPlatformBotObj?.id, systemSettingsConfig]);

  return filteredVars;
};

export const useCustomLabels = () => {
  return useSelector((state: RootState) => state.entities.customLabels);
};

export const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/* For debugging useEffect https://stackoverflow.com/a/59843241 */
export const useEffectDebugger = (effectHook: any, dependencies: any, dependencyNames: any = []) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum: any, dependency: any, index: any) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});
  if (Object.keys(changedDeps).length) {
    // eslint-disable-next-line no-console
    console.log('[use-effect-debugger] ', changedDeps);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies);
};
export const useOverrideNlpSetting = () => {
  const isConnectedToFb = useSelector((state: RootState) => state.pageUIState.isConnectedToFb);
  const dispatch = useDispatch();
  const botId = useCurrentPlatformBotObj()?.botId;
  useEffect(() => {
    if (isConnectedToFb && botId) {
      dispatch(getBotSettings(botId));
    }
  }, [botId, dispatch, isConnectedToFb]);
};

export const useCurrentAccount = () => {
  return useSelector((state: RootState) => state.entities.account);
};

export const useCurrentBot = () => {
  const currentPlatformBot = useCurrentPlatformBotObj();

  const currentBotId = currentPlatformBot && currentPlatformBot.botId;
  const currentBots = useSelector((state: RootState) => state.entities.bots);

  return currentBotId && currentBots && currentBots[currentBotId];
};
export const useBots = () => {
  return useSelector((state: RootState) => state.entities.bots);
};
/* eslint-disable @typescript-eslint/naming-convention*/
export const useAmplitudeEventProps = () => {
  const currPlatformObj = useCurrentPlatformBotObj();
  return { platform: currPlatformObj?.platform || '', platform_bot_id: useIds().platformBotId || '', platform_bot_name: currPlatformObj?.name || '' };
};

export const usePlatformActionConfig = () => {
  return useSelector((state: RootState) => state.platformActionConfig.configData);
};
export const useSupportedPlatforms = () => {
  const supportedPlatforms = useSelector((state: RootState) => state.platformActionConfig.supportedPlatforms);
  return new Set(supportedPlatforms);
};
export const useAvailableTabsSubTabs = () => {
  return useSelector((state: RootState) => state.pageUIState.applicableTabsSubTabs);
};

export const useModerationMode = () => {
  return useSelector((state: RootState) => state.pageUIState.moderationMode);
};
export const useCommentsMode = () => {
  return useSelector((state: RootState) => state.pageUIState.commentsMode);
};
export const useCreateTemplatePermission = () => {
  return useUserPermissions(permissionEntities.PUBLICREPLYTEMPLATE, permissionType.CREATE_PUBLIC_REPLY_TEMPLATE);
};

export const useMessagingEnabledInfo = () => {
  const bot: any = useCurrentBot();
  return bot?.config?.indexMessage?.enabled;
};

export const usePageHealthDetails = () => {
  return useSelector((state: RootState) => state.profileManagement.pageHealthDetails);
};

export const usePlatfromUserById = (platformUserId: string) => {
  const platformUsers = useSelector((state: RootState) => state?.entities?.platformUsers);
  return platformUsers?.[platformUserId];
};

export const useUserProfilePic = (platformUserId: string) => {
  const platformUsers = useSelector((state: RootState) => state?.entities?.platformUsers);
  return platformUsers?.[platformUserId]?.profilePic;
};

export const useFetchCustomLabels = (fetchGlobalLabels: boolean = false) => {
  const dispatch = useDispatch();
  const bot: any = useCurrentBot() || {};
  useEffect(() => {
    bot.id && dispatch(fetchCustomLabelsForABot(fetchGlobalLabels));
  }, [dispatch, bot.id, fetchGlobalLabels]);
};

export const useResetFilters = () => {
  const dispatch = useDispatch();
  const resetFilters = () => {
    Object.entries(defaultFiltersForPages()).forEach((entry: any) => {
      const page = entry[0];
      const filters = page !== pageKeysForFilter.INBOX ? entry[1] : { filters: '' };
      dispatch(setFilter(page, pageFilterKeys.FILTERS, filters.filters, false));
    });
  };
  return { resetFilters };
};

// useInitializePageFilter: this function ideally should be called before useUseSetQueryParamFromReduxToUrl in a component
export const useInitializePageFilter = (pageName: string, initFilter: boolean = true, filterPresets?: GenericObject) => {
  const dispatch = useDispatch();
  const [initialPageFiltersStored, setInitialPageFiltersStored] = useState(false);
  useEffect(() => {
    initFilter && dispatch(initializePageFilter(pageName, filterPresets));
    setInitialPageFiltersStored(true); //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageName, initFilter]);

  return initialPageFiltersStored;
};

// if we want not to update the page url until redux is updated with initial values, we can pass correct value of initialFiltersStoredInRedux flag
export const useUseSetQueryParamFromReduxToUrl = (pageName: string, initialFiltersStoredInRedux: boolean = true) => {
  const filtersFromRedux = useFilterForPage(pageName);
  const { pathname, search, state } = useLocation();
  const history = useHistory();
  const setParam = pathname.split('/')[pathname.split('/').length - 1] !== AmpLitePages.BROADCASTS;
  useEffect(() => {
    setParam && filtersFromRedux && initialFiltersStoredInRedux && setQueryParamsFromObject(filtersFromRedux, pathname, search, history, state);
  }, [filtersFromRedux, pathname, search, history, state, setParam, initialFiltersStoredInRedux]);
};

export const useCurrentPlatform = () => {
  const match = useRouteMatch();
  const currentRouteParams = getRouteParams(match.url);
  const platformBotId = currentRouteParams.platformBotId;
  const pageUIState = useSelector((state: RootState) => state?.pageUIState);
  const platformBots = useSelector((state: RootState) => state.entities?.platformBots);

  return platformBotId ? platformBots && platformBots[platformBotId]?.platform : platformBots && platformBots[pageUIState.selectedPlatformBot || '']?.platform;
};

export const useGetAuthToken = () => {
  const authToken: string = useSelector((state: RootState) => state?.auth.authToken) || '';
  return authToken;
};
interface EventOptions {
  enabled?: boolean;
  target?: GlobalEventHandlers;
}
export const useEventListener = (eventType: keyof GlobalEventHandlersEventMap, handler: (e: Event) => void, { enabled = true, target = document }: EventOptions = {}) => {
  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  });
  useEffect(() => {
    if (!enabled) {
      return;
    }
    function internalHandler(e: Event) {
      return handlerRef.current(e);
    }

    target.addEventListener(eventType, internalHandler);

    return () => {
      target.removeEventListener(eventType, internalHandler);
    };
  }, [eventType, enabled, target]);
};

export const useOnLogin = (history: History) => {
  const dispatch = useDispatch();
  const onLoginSuccess = (email: string) => {
    const onSuccess = (accountId?: string, userId?: string, _currentAccount?: Account) => {
      // if (accountId) {
      /*analytics call needs to be made for both single/multi account users*/
      registerAmplifyAnalytics.setAccountUserIdInConfig(accountId || '', userId || '', IsExternalUser(email));
      registerAmplifyAnalytics.logIn();
      /*fetching currentUser's all Roles after current user account fetch*/
      dispatch(getCurrentUserRoles());
      /*getUsers,getPlatformActionConfig call are made in app-layout for single account & getBotsAndPlatformBots call made in useLoadInitialAccountAndPage hook's useEffect */
      // const currentAccountConfig: AccountLiteUIConfig = currentAccount?.config?.amplifyLiteUIConfig as AccountLiteUIConfig;
      // const onGetPlatformActionConfigSuccess = () => dispatch(getBotsAndPlatformBots(accountId));
      // const onGetUsersSuccess = () =>
      //   dispatch(getPlatformActionConfig({ accountId, accountConfig: currentAccountConfig, moderationMode, onSuccess: onGetPlatformActionConfigSuccess }));
      // dispatch(getUsers(onGetUsersSuccess));
      // }
    };
    dispatch(getUserAccounts(onSuccess, '', history));
  };
  return onLoginSuccess;
};

export const useLogout = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch(logOut());
    dispatch(setPageUIState({ key: 'isCurrentUserDetailsFetched', value: false }));
  };
};

export const useIsMounted = (onMount?: () => void | undefined, onUnmount?: () => void | undefined) => {
  const isMountedRef = useRef<boolean>(false);
  const { current: isMounted } = isMountedRef;
  useEffect(() => {
    isMountedRef.current = true;
    onMount?.();
    return () => {
      isMountedRef.current = false;
      onUnmount?.();
    }; //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isMounted;
};

export const useGenerateProUrl = (path: string) => {
  const accountId = useAccountId() || '';
  const bot: any = useCurrentBot();
  const proEquivalentURL = getEquivalentUrlForPRO();
  const authToken = useGetAuthToken();
  const currPlatformObj = useCurrentPlatformBotObj();
  const botId = currPlatformObj?.botId || '';
  const versionId = bot?.activeVersionId || '';
  if (accountId && authToken && botId && versionId && path) {
    return `${proEquivalentURL}?token=${authToken}&goTo=${`accounts/${accountId}/bots/${botId}/versions/${versionId}/${path}`}`;
  }
  return '';
};

export const useIsWhatsAppPlatform = (platform?: string) => {
  const platformActionConfig = usePlatformActionConfig();
  if (platform && platformActionConfig[platforms.WHATSAPP]?.providers) {
    return Boolean(platformActionConfig[platforms.WHATSAPP]?.providers?.includes(platform));
  }
  return false;
};

export const useSidebarItems = () => {
  const currentAccount = useSelector((state: RootState) => state.entities.account);
  const isOverrideNlpEnabled = useSelector((state: RootState) => state.pageUIState.isOverrideNlpEnabled);
  const isInboxEnabled = useMessagingEnabledInfo();
  const supportedPlatforms = useSupportedPlatforms();
  const isAdmin = useIsAdminUser();
  const isMobile = useIsMobile();
  const actionPlatformConfig = usePlatformActionConfig();
  const isBotworxAdmin = useIsBotworxAdminUser();
  const currentPlatformBotObj = useCurrentPlatformBotObj();
  const platform = (currentPlatformBotObj && currentPlatformBotObj.platformAsInSystemSettings) || '';
  const platformActionConfig = actionPlatformConfig[platform]?.actions;
  const isCliqzTypeBotConfig = useIsCliqzTypeBotConfig();
  const hasKeywordGroupViewPermission = useUserPermissions(permissionEntities.BOT, permissionType.VIEW_BOT_NLP_DATA);
  const hasTagAutomationViewPermission = useUserPermissions(permissionEntities.BOT, permissionType.VIEW_PRIVATE_REPLIES);
  const hasTemplateViewPermission = useUserPermissions(permissionEntities.BOT, permissionType.VIEW_PUBLIC_REPLY_TEMPLATES);
  const viewACMReportsPermission = useUserPermissions(permissionEntities.BOT, permissionType.VIEW_ACM_REPORTS);
  const viewInbox = useUserPermissions(permissionEntities.BOT, permissionType.VIEW_MESSAGE_INBOX);
  const viewSegmentsPermission = useUserPermissions(permissionEntities.BOT, permissionType.VIEW_SEGMENTS);
  const permissions = { hasKeywordGroupViewPermission, hasTagAutomationViewPermission, viewACMReportsPermission, hasTemplateViewPermission, viewInbox, viewSegmentsPermission };
  const sideBarItems = getSidebar(
    isOverrideNlpEnabled,
    permissions,
    platformActionConfig,
    platform,
    { isInboxEnabled, isMobile },
    isAdmin,
    isBotworxAdmin,
    isCliqzTypeBotConfig,
    Array.from(supportedPlatforms),
    actionPlatformConfig,
    currentAccount,
  );
  return sideBarItems;
};

/**
 * A custom hook that creates a ref for a function, and updates it on every render.
 * The new value is always the same function, but the function's context changes on every render.
 */
export function useRefEventListener(fn: any) {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  return fnRef;
}

export const useLogUserActivity = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useCurrentUser();
  const title = getRouterTitle(location.pathname, routeToPage);

  const onDone = () => {
    const userActivity = { userId: user?.id, lastLoggedTime: Date.now() };
    localStorage.setItem(UserActivityKey, JSON.stringify(userActivity));
  };

  const handleLogUserActivity = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const userActivity = JSON.parse(localStorage.getItem(UserActivityKey) || '{}');
    if (user && (!userActivity.userId || userActivity?.userId !== user?.id || Date.now() - userActivity.lastLoggedTime > LogActivityTimeLimit)) {
      dispatch(updateLastLoggedIn(title, onDone));
    }
  };

  return handleLogUserActivity;
};

/*eslint-disable max-lines,max-len,no-useless-escape*/
import { GroupConditionType } from '../automation/types';
import { dateRenderer, rowValueRenderer } from '../utility/utilities';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { variableRegex } from '../../utils/regex';
import { countryNamesAndValuesForBroadcastTargeting } from 'src/common/constants';

export const amplifyDomain = 'app.amplify.ai';
export const cliqzDomain = 'app.cliqz.ai';
export const trillerDomain = 'triller.afy.ai';
export const amplifyFeatureDomain = 'amplify-lite-ui-feature.s3-website-us-east-1.amazonaws.com';
export const fbMeURL = 'https://m.me/';
export const IgMeURL = 'https://ig.me/m/';
export const messageKeyword = 'Keyword is not available please contact the support team.';
/* eslint-disable @typescript-eslint/naming-convention */
export const broadcastConstants = {
  RECENT: 'recent',
  DRAFT: 'draft',
  COMPLETED: 'archived',
  SCHEDULED: 'scheduled',
  REPEAT: 'repeat',
  TEMPLATES: 'templates',
};
export const urlClickTrackWarning = 'Url Click tracking is disabled. You may want to enable it for clicking tracking or shortening.';
export const notifBroadcastTestWarning = `Please ensure the broadcast is tested and verified before sending or scheduling.`;
export const tabsWithBroadcastsEverStarted = [broadcastConstants.DRAFT, broadcastConstants.TEMPLATES, broadcastConstants.SCHEDULED, broadcastConstants.REPEAT];

export const waTemplateStatus = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
};
export const broadcastNonArchivePaginationCount = 20;
export const broadcastArchivePaginationCount = 10;
export const TEMPLATE_BROADCAST_INITIALS_REGEX = /\[Template\]/gi;
export const ViewBroadcastConstants = {
  DETAILS: 'details',
  ENGAGEMENT: 'engagement',
  AUDIT_DETAILS: 'auditDetails',
};
export const broadcastsTabsRoutes = {
  [broadcastConstants.RECENT]: 'recent',
  [broadcastConstants.DRAFT]: 'draft',
  [broadcastConstants.COMPLETED]: 'archived',
  [broadcastConstants.SCHEDULED]: 'scheduled',
  [broadcastConstants.REPEAT]: 'repeat',
  [broadcastConstants.TEMPLATES]: 'templates',
};
export const BROADCAST_AUDIT_LOGS_PROPERTIES: { [key: string]: string } = {
  ARCHIVED_BY: 'archivedBy',
  ARCHIVED_ON: 'archivedOn',
  LAST_UPDATED_BY: 'lastUpdatedBy',
  LAST_UPDATED_ON: 'lastUpdatedOn',
};
const getLeftRightColDefaultDetails = (leftColLabel?: string, leftColRenderer?: any, leftName?: string, rightColLabel?: string, rightColRenderer?: any, rightName?: string) => {
  const detail = {
    leftColumn: {
      label: leftColLabel,
      value: '',
      ...(leftName ? { name: BROADCAST_AUDIT_LOGS_PROPERTIES[leftName] } : {}),
      render: leftColRenderer,
    },
    rightColumn: {
      label: rightColLabel,
      value: '',
      ...(rightName ? { name: BROADCAST_AUDIT_LOGS_PROPERTIES[rightName] } : {}),
      render: rightColRenderer,
    },
  };
  return detail;
};
export const BROADCAST_DETAILS: any = [
  {
    leftColumn: {
      label: 'Sent by',
      value: '',
      render: rowValueRenderer,
    },
    rightColumn: {
      label: 'Sent on',
      value: '',
      render: dateRenderer,
    },
  },
  {
    leftColumn: {
      label: 'Last Updated By',
      value: '',
      name: BROADCAST_AUDIT_LOGS_PROPERTIES.LAST_UPDATED_BY,
      render: rowValueRenderer,
    },
    rightColumn: {
      label: 'Last Updated on',
      name: BROADCAST_AUDIT_LOGS_PROPERTIES.LAST_UPDATED_ON,
      value: '',
      render: dateRenderer,
    },
  },
  {
    leftColumn: {
      label: 'Created by',
      value: '',
      render: rowValueRenderer,
    },
    rightColumn: {
      label: 'Created on',
      value: '',
      render: dateRenderer,
    },
  },
  {
    leftColumn: {
      label: 'Archived by',
      name: BROADCAST_AUDIT_LOGS_PROPERTIES.ARCHIVED_BY,
      value: '',
      render: rowValueRenderer,
    },
    rightColumn: {
      label: 'Archived on',
      name: BROADCAST_AUDIT_LOGS_PROPERTIES.ARCHIVED_ON,
      value: '',
      render: dateRenderer,
    },
  },
];
export const SEGMENT_DETAILS: any = [
  getLeftRightColDefaultDetails('Last Updated by', rowValueRenderer, 'LAST_UPDATED_BY', 'Last Updated on', dateRenderer, 'LAST_UPDATED_ON'),
  getLeftRightColDefaultDetails('Created by', rowValueRenderer, undefined, 'Created on', dateRenderer, undefined),
  getLeftRightColDefaultDetails('Renamed by', rowValueRenderer, 'RENAMED_BY', 'Renamed on', dateRenderer, 'RENAMED_ON'),
];

export const broadcastTypes = {
  STANDARD: 'broadcast',
  SUBSCRIPTION: 'subscription-broadcast',
  SUBSCRIPTION_MISSING: 'subscription-missing-broadcast',
  NOTIFICATION: 'notification-broadcast',
  ADMIN: 'admin-broadcast',
  POLL: 'poll-broadcast',
  SEGMENT_COUNTER: 'segment-counter',
  ADMIN_TOOL: 'admin-tool-broadcast',
};
export const broadcastTypeLabels: any = {
  [broadcastTypes.STANDARD]: 'Standard',
  [broadcastTypes.SUBSCRIPTION]: 'Subscription',
  [broadcastTypes.NOTIFICATION]: 'Marketing',
  [broadcastTypes.SUBSCRIPTION_MISSING]: 'Subscription Missing',
  [broadcastTypes.ADMIN]: 'Admin',
  [broadcastTypes.POLL]: 'Poll',
  [broadcastTypes.SEGMENT_COUNTER]: 'Segment Counter',
};

export const tooltip = {
  ENABLE_URL_TRACKING:
    'It will enable short url generation and click tracking for URL configured in text. Please include http:// or https:// in your URL(s) e.g. https://amplify.ai',
  CONSIDER_INPUT_NODE: 'Users sending text in response to this broadcast will go to the selected module. You can optionally capture this text input in the selected variable.',
  SET_USER_VARIABLE: 'You can set the value of a variable for users that receive the broadcast. This will persist for that user after this broadcast.',
  MESSAGE_SEGMENTS:
    'A SMS message is made up of one or more message segments (max of 10). A message segment includes up to 160 characters. The user always receives a single SMS message even if it is broken into multiple message segments. Message segments are primarily used for billing purposes.',
  BROADCAST_SESSIONS: 'Total users broadcasted to',
  ENGANGED_SESSIONS: 'Total users who typed or clicked first time on a broadcast message',
  ALL_CLICKS: 'Total clicks attributed to this broadcast.This includes all types of clicks(button, quick reply, persistent menu) generated by the user within a broadcast session',
  SEGMENT_COUNT_NOT_UPDATED: 'We do not support estimating counts for expression conditions and values with variables.',
  SEGMENT_COUNT_FOR_ADVANCE_BROADCAST: 'We do not support estimating counts for advanced broadcasts.',
  SENDASMMS:
    'This feature allows Twilio customers to deliver those longer messages to their recipients via a single MMS message instead of it being broken up into smaller SMS segments.',
  MIGRATE_TO_MERGE_TAG: 'Local Variables used in Text are converted to Merge Tags & are removed from broadcast.',
};

type BroadcastConstantsTypes = {
  CREATE_BROADCAST_NAME: string;
  SET_MEDIA_URL: string;
  SET_REPLY_CONTENT: string;
  SET_BROADCAST_RESPONSE: string;
  SET_CURRENT_ACTIVE_TAB: string;
  SET_DELETED_BROADCAST_FETCHED: string;
  SET_TEST_BROADCAST_SENT: string;
  FETCH_TEST_LINK: string;
  FETCH_NOTIFICATION_LABELS: string;
};

export const broadcastConstant: BroadcastConstantsTypes = {
  CREATE_BROADCAST_NAME: 'CREATE_BROADCAST_NAME',
  SET_BROADCAST_RESPONSE: 'SET_BROADCAST_RESPONSE',
  SET_MEDIA_URL: 'SET_MEDIA_URL',
  SET_REPLY_CONTENT: 'SET_REPLY_CONTENT',
  SET_CURRENT_ACTIVE_TAB: 'SET_CURRENT_ACTIVE_TAB',
  SET_DELETED_BROADCAST_FETCHED: 'SET_DELETED_BROADCAST_FETCHED',
  SET_TEST_BROADCAST_SENT: 'SET_TEST_BROADCAST_SENT',
  FETCH_TEST_LINK: 'FETCH_TEST_LINK',
  FETCH_NOTIFICATION_LABELS: 'FETCH_NOTIFICATION_LABELS',
};
export const broadcastAction = {
  DELETE: 'delete',
  EDIT: 'edit',
  CLONE: 'clone',
  ACTIVATE: 'activate',
  VIEW: 'view',
};
export const searchByOptionsForTest = [
  { value: 'name', label: 'Name' },
  { value: 'platformUserId', label: 'Platform User Id' },
];
export const searchByOptionsForTestPhone = [{ value: 'phoneNumber', label: 'Phone Number' }];
export const broadcastScheduleConst = {
  NOW: {
    label: 'Now',
    value: 'immediate',
  },
  LATER: {
    label: 'Later',
    value: 'scheduled',
  },
};

export const broadcastLabelConst = {
  ALL: {
    label: 'All',
    value: 'All',
  },
  SPECIFIC: {
    label: 'Specific',
    value: 'Specific',
  },
};

export const broadcastRepeatPatterns = {
  NONE: 'None',
  HOURLY: 'Every Hour',
  DAILY: 'Every Day',
  WEEKLY: 'Every Week',
  MONTHLY: 'Every Month',
};

export const broadcastRepeatTypes = [
  { value: 'None', label: 'None' },
  { value: 'Every Hour', label: 'Every Hour' },
  { value: 'Every Day', label: 'Every Day' },
  { value: 'Every Week', label: 'Every Week' },
  { value: 'Every Month', label: 'Every Month' },
];

export const broadcastResponseTypes = {
  MODULE: 'module',
  TEXT: 'text',
  SMS: 'sms',
  MMS: 'mms',
  TEMPLATES: 'platformTemplate',
};
export const broadcastResponseTypesOptions = [
  { value: broadcastResponseTypes.TEXT, label: 'Text' },
  { value: broadcastResponseTypes.MODULE, label: 'Module' },
  { value: broadcastResponseTypes.SMS, label: 'SMS' },
  { value: broadcastResponseTypes.MMS, label: 'MMS' },
];
export const broadcastResponseTypesOptionsWhatsapp: any[] = Array.from(
  new Set([
    { value: broadcastResponseTypes.TEXT, label: 'Text' },
    { value: broadcastResponseTypes.MODULE, label: 'Module' },
    { value: broadcastResponseTypes.TEMPLATES, label: 'Templates' },
  ]),
);
export const createbraodcastSegmentCountArea = 'createbraodcastSegmentCountArea';
export const subscribedIfStmtCondition: GroupConditionType = {
  ifStatements: [
    {
      variable: {
        name: 'subscribed',
        macro: '{{subscribed}}',
        type: '',
      },
      value: 'true',
      condition: 'equal',
    },
  ],
  logicalOperator: 'and',
};
export const broadcastToastMsgTimeStampInSeconds = 300; // 5 mins
export const fanCountConstant = {
  fan: 'User',
  fans: 'Users',
};
export const usNumbersCondition: GroupConditionType = {
  ifStatements: [
    {
      variable: {
        name: 'Country Code',
        macro: '{{profile.location.countryCode}}',
        type: 'profile',
      },
      value: 'US',
      condition: 'equal',
    },
  ],
  logicalOperator: 'and',
};
export const moduleComponentTypes = {
  GALLERY: 'gallery',
};
export const broadcastMessageConstants = {
  SENT: 'sent',
  READ: 'read',
  ENGAGED: 'engaged',
  CLICKS: 'clicks',
  MESSAGETYPE: 'count',
};
export const broadcastMetricsHeaderConstants = {
  [broadcastMessageConstants.SENT]: 'Total Broadcast Sent',
  [broadcastMessageConstants.READ]: 'Read Messages',
  OPENRATE: 'Open Rate',
  [broadcastMessageConstants.CLICKS]: 'All Clicks',
  [broadcastMessageConstants.MESSAGETYPE]: 'Messages Typed',
  [broadcastMessageConstants.ENGAGED]: 'Total Broadcast Engaged',
  ENGAGEMENTRATE: 'Broadcast Engagement Rate',
};

export const tooManyCharactersWarning = 'Allowed max Characters exceeded. Remove characters from message to proceed';
// export const charLimitMayExceedWarning = 'The max Characters limit may exceed due to variables used as their length cannot be accurately estimated.';
export const charLimitMayExceedWarning = `The message is almost at the max character limit. If the variables cause it to be exceeded the message will get truncated.`;

export const warningUSFilterWithOR = `The targeting conditions need to be modified to include US only targeting.`;

export const broadcastVariableMacro = '{{message.broadcastInfo._id}}';
export const broadcastVariableMacroSquareBracket = "{{message.broadcastInfo['_id']}}";
export const broadcastVariableId = 'BroadcastId';
export const actBlueHost = 'secure.actblue.com';
export const actBlueBaseUrl = 'https://secure.actblue.com/';
export const actBlueAmountText = '&amount=';
export const actBlueUrlrefcodeParam = `refcode`;
export const actBlueUrlrefcodeParams = `?${actBlueUrlrefcodeParam}=`;
export const actBlueRefCodABIDParam = 'refcodeABID';
export const actBlueUrlrefcode2Params = `&refcode2=`;
export const encodedSpecialCharacters = ['%26', '%3D', '%2F'];
export const getBroadcastIdLocalVariable = (name: string) => {
  name = name && name.trim();
  return {
    name,
    botId: null,
    type: 'user',
    macro: broadcastVariableMacro,
    id: uuid(),
  };
};
const regexProhibitingSpaceAndBraces = /[^(\s|<|>)]/g;
export const actBlueURLRegex = new RegExp(
  '(https?://|www\\.)secure\\.actblue\\.com/(' +
    regexProhibitingSpaceAndBraces.source +
    '+)\\?((&|&amp;)?' +
    regexProhibitingSpaceAndBraces.source +
    '+=(' +
    variableRegex.source +
    '|(' +
    regexProhibitingSpaceAndBraces.source +
    ')+)?)+',
  'g',
);
// export const actBlueURLRegex = /(https?:\/\/|www\.)secure.actblue.com(\/([^\s]+))+\?(((&|&amp;)?([^\s]+)=({{(.*?)}}|({%\s*if\s+[\s\S]*?%\}[\s\S]*?(?:{%\s*else\s*%\}[\s\S]*?)?{%\s*endif\s*%\})|([^\s]+)))+)?/g;
export const timezoneTypes = {
  BOT: 'bot',
  USER: 'user',
};
export const timezoneOptions = [
  { value: timezoneTypes.BOT, label: 'Account' },
  { value: timezoneTypes.USER, label: 'User' },
];
export const waTemplateErrors = {
  targetingOnMacroFields: 'Please enter valid macro values for titles and header texts.',
  targetingOnActionModules: 'Please enter valid modules for action button(s).',
  targetingToCheckValidActionUrl: 'Please enter valid webview url for action button(s).',
  targetingOnImageUrls: 'Please enter valid image/video urls for gallery item(s).',
};

export const mergeTagConstant = 'mergeTag';
export const mentionLinkConstant = 'mentionLink';
export const broadcastEngagementPages: { [key: string]: string } = {
  HOME_PAGE: 'homePage',
  // LISTING_PAGE: 'listingPage', //not in use currently
  BROADCAST_CARD_STATS: 'broadcastCardStats',
  BROADCAST_ENGAGEMENT_PAGE: 'broadcastEngagementPage',
};
export const elasticKeysMap = {
  READ: 'read',
  SENT: 'sent',
  DELIVERED: 'delivered',
  ENGAGED: 'engaged',
  BROADCASTS: 'broadcasts',
  CLICKS: 'clicks',
};
export const apiIndexVariablesMap = {
  broadcasts: ['broadcasts', 'delivered', 'engaged', 'read', 'sent', 'clicks'],
  clicks: ['clicks'],
  broadcastsSubscriptions: ['new', 'canceled', 'blocked'],
  notificationBroadcastOptIns: ['optedIn', 'optedOut', 'blocked', 'expired'],
  subscriptions: ['uniqueUserNew', 'uniqueUserCanceled', 'uniqueUserBlocked'],
  broadcastsDonations: ['donationAmount', 'donationCount', 'refundAmount', 'refundCount'],
};

export const broadcastEngagementDefaultSettings = {
  pageStatsMetadata: {
    fansSent: {
      esKey: 'broadcasts',
      indexName: 'broadcasts',
      label: 'Users Sent',
      helpTextToConfigure: '',
      enableOn: ['homePage', 'broadcastEngagementPage'],
      disabledByDefault: false,
    },
    messageSent: {
      esKey: 'sent',
      indexName: 'broadcasts',
      label: 'Messages Sent',
      helpTextToConfigure: '',
      enableOn: ['homePage', 'broadcastEngagementPage', 'broadcastCardStats'],
      disabledByDefault: false,
    },
    newFans: {
      esKey: 'uniqueUserNew',
      indexName: 'subscriptions',
      label: 'New Users',
      helpTextToConfigure: '',
      enableOn: ['homePage'],
      disabledByDefault: false,
    },
    engagedClicks: {
      indexName: 'session',
      label: 'Engagements',
      formula: '{textInputs}+{allClicks}',
      helpTextToConfigure: '',
      enableOn: ['homePage'],
      disabledByDefault: false,
    },
    allClicks: {
      esKey: 'allClicks',
      indexName: 'session',
      label: 'Clicks',
      helpTextToConfigure: '',
      enableOn: ['homePage'],
      disabledByDefault: false,
    },
    engagement: {
      esKey: 'engaged',
      indexName: 'broadcasts',
      label: 'Engagements',
      helpTextToConfigure: '',
      enableOn: ['homePage', 'broadcastEngagementPage'],
      disabledByDefault: false,
    },
    engagementPercent: {
      label: 'Engagement %',
      helpTextToConfigure: '',
      formula: '{engaged}/{sent}*100',
      enableOn: ['broadcastEngagementPage', 'broadcastCardStats'],
      disabledByDefault: false,
    },
    clicks: {
      esKey: 'clicks',
      indexName: 'clicks',
      label: 'Clicks',
      helpTextToConfigure: '',
      enableOn: ['homePage', 'broadcastEngagementPage'],
      disabledByDefault: false,
    },
    clicksPercent: {
      label: 'Clicks %',
      helpTextToConfigure: '',
      formula: '{clicks}/{sent}*100',
      enableOn: ['broadcastEngagementPage', 'broadcastCardStats'],
      disabledByDefault: false,
    },
    delivery: {
      esKey: 'delivered',
      indexName: 'broadcasts',
      label: 'Delivered',
      helpTextToConfigure: '',
      enableOn: ['homePage', 'broadcastEngagementPage'],
      disabledByDefault: true,
    },
    deliveryPercent: {
      label: 'Delivery %',
      helpTextToConfigure: '',
      formula: '{delivered}/{sent}*100',
      enableOn: ['broadcastEngagementPage'],
      disabledByDefault: true,
    },
    read: {
      esKey: 'read',
      indexName: 'broadcasts',
      label: 'Read',
      helpTextToConfigure: '',
      enableOn: ['homePage', 'broadcastEngagementPage'],
      disabledByDefault: true,
    },
    readPercent: {
      label: 'Read %',
      helpTextToConfigure: '',
      formula: '{read}/{sent}*100',
      enableOn: ['broadcastEngagementPage'],
      disabledByDefault: true,
    },
    messageTyped: {
      esKey: 'count',
      indexName: 'text_inputs',
      label: 'Message Typed',
      helpTextToConfigure: '',
      enableOn: ['homePage', 'broadcastEngagementPage'],
      disabledByDefault: true,
    },
    optOutRate: {
      label: 'Opt-out %',
      helpTextToConfigure: '',
      formula: '({canceled}+{blocked})/{sent}*100',
      enableOn: ['broadcastEngagementPage'],
      disabledByDefault: false,
    },
    donation: {
      label: 'Donation',
      helpTextToConfigure: '',
      formula: '{donationAmount}-{refundAmount}',
      // esKey: 'donation',
      indexName: 'donations',
      enableOn: ['broadcastEngagementPage', 'broadcastCardStats'],
      disabledByDefault: true,
      prefixToValue: '$',
      preventNegativeValue: true,
      // hideIfZeroValue: true,
    },
    donationAverage: {
      label: 'Average Donation',
      helpTextToConfigure: '',
      formula: '({donationAmount}-{refundAmount})/({donationCount}-{refundCount})',
      indexName: 'donations',
      enableOn: ['broadcastEngagementPage'],
      disabledByDefault: true,
      prefixToValue: '$',
      preventNegativeValue: true,
      // hideIfZeroValue: true,
    },
    donationCount: {
      label: 'Donations Count',
      helpTextToConfigure: '',
      formula: '{donationCount}',
      indexName: 'actblue_events',
      enableOn: ['broadcastEngagementPage'],
      disabledByDefault: true,
      preventNegativeValue: true,
    },
    // donationEfficacy: {
    //   label: 'Donations Efficacy',
    //   helpTextToConfigure: '',
    //   formula: '{donationAmount}/({segmentsCount}*{sent})',
    //   prefixToValue: '$',
    //   indexName: 'broadcasts',
    //   // indexName: 'accountConfig',
    //   enableOn: ['broadcastEngagementPage'],
    //   disabledByDefault: true,
    //   preventNegativeValue: true,
    //   toFixedDecimal: 2,
    //   isDynamic: ['segmentsCount'],
    // },
    estimatedSMSSegmentCost: {
      label: 'Estimated Cost',
      helpTextToConfigure: '',
      formula: '({costPerSMSSegmentsInCents}*{segmentsCount}*{sent})/100',
      indexName: 'accountConfig',
      enableOn: ['broadcastEngagementPage'],
      disabledByDefault: true,
      isDynamic: ['segmentsCount'],
      prefixToValue: '$',
      toFixedDecimal: 2,
      toFixedDecimalThreshold: 1000,
      preventNegativeValue: true,
    },
    estimatedMMSCost: {
      label: 'Estimated Cost',
      helpTextToConfigure: '',
      formula: '({costPerMMSInCents}*{segmentsCount}*{sent})',
      indexName: 'accountConfig',
      enableOn: ['broadcastEngagementPage'],
      disabledByDefault: true,
      isDynamic: ['segmentsCount'],
      preventNegativeValue: true,
      toFixedDecimal: 2,
      toFixedDecimalThreshold: 1000,
      prefixToValue: '$',
      showCarrierFeeFlag: true,
    },
  },
  pageStatsSequenceConfig: {
    homePage: ['newFans', 'engagedClicks', 'allClicks'], //'messageSent'
    // listingPage: ['engagement', 'fansSent', 'clicks', 'messageSent'],
    broadcastCardStats: ['messageSent', 'engagementPercent', 'clicksPercent', 'donation'],
    broadcastEngagementPage: [
      'fansSent',
      'messageSent',
      'engagement',
      'engagementPercent',
      'clicks',
      'clicksPercent',
      'optOut',
      'optOutRate',
      'donation',
      'donationCount',
      'donationAverage',
      'estimatedSMSSegmentCost',
      'estimatedMMSCost',
    ],
  },
};
export const escapeChars = ['^', '{', '}', '[', ']'];

// export const refCodeVersusBroadcastIdForDemo: any = {
//   AU_ABS_TXT_GEN_GEN_TXT_20240110_PM1_V1_S1: '659d964df0a03518738f37df',
//   AU_ABS_TXT_GEN_GEN_TXT_20240110_PM1_V2_S1: '659d96e3b9cc52187acc99ef',
//   AU_ABS_TXT_GEN_GEN_TXT_20240110_PM1_V3_S1: '659eeac95d351406901ba0a6',
//   AU_ABS_TXT_GEN_GEN_TXT_20240110_PM1_V4_S1: '659eeb6b962e1d19d7bee62e',
//   AU_ABS_TXT_GEN_GEN_TXT_20240110_PM1_V5_S1: '659f23195d351406901ba0d4',
// };
// export const refCodesForDemo = [
//   'AU_ABS_TXT_GEN_GEN_TXT_20240110_PM1_V1_S1',
//   'AU_ABS_TXT_GEN_GEN_TXT_20240110_PM1_V2_S1',
//   'AU_ABS_TXT_GEN_GEN_TXT_20240110_PM1_V3_S1',
//   'AU_ABS_TXT_GEN_GEN_TXT_20240110_PM1_V4_S1',
//   'AU_ABS_TXT_GEN_GEN_TXT_20240110_PM1_V5_S1',
// ];

export const donationUiVariables = ['donation', 'donationAverage', 'donationCount'];
export const segmentCostUiVariables = ['estimatedSMSSegmentCost', 'estimatedMMSCost'];
export const donationIndexStartDate = moment('2024-01-18T12:34:56.789Z');
export const segmentTypeConst = 'segment';
export const segmentTagTypeConst = 'segmentTag';
export const broadcastTabsToBeSortedByLastUpdatedDate = [broadcastConstants.DRAFT, broadcastConstants.TEMPLATES];

export const twigDateFormatOptions = [
  { label: 'MM/DD/YYYY (e.g., 10/25/2024)', value: 'm/d/Y' },
  { label: 'DD/MM/YYYY (e.g., 25/10/2024)', value: 'd/m/Y' },
  { label: 'YYYY-MM-DD (e.g., 2024-10-25)', value: 'Y-m-d' },
  { label: 'MMMM D, YYYY (e.g., October 25, 2024)', value: 'F j, Y' },
  { label: 'D MMMM YYYY (e.g., 25th October 2024)', value: 'jS F Y' },
  { label: 'YYYY/MM/DD (e.g., 2024/10/25)', value: 'Y/m/d' },
  { label: 'DD-MM-YYYY (e.g., 25-10-2024)', value: 'd-m-Y' },
  { label: 'MM-DD-YYYY (e.g., 10-25-2024)', value: 'm-d-Y' },
  { label: 'MMMM DD, YYYY (e.g., October 25, 2024)', value: 'F d, Y' },
  { label: 'YYYY.MM.DD (e.g., 2024.10.25)', value: 'Y.m.d' },
  { label: 'ISO 8601 (e.g., 2024-10-25T00:00:00+00:00)', value: 'c' },
  { label: 'RFC 2822 (e.g., Fri, 25 Oct 2024 00:00:00 +0000)', value: 'r' },
];

export const broadcastMetricsHelpText =
  'Track your broadcast performance with key metrics like messages sent, reads, clicks, and engagement rates. "N/A" indicates unavailable data. Use these insights to enhance your messaging strategy.';

export const advancedTargetingHelpText =
  'Advanced Targeting allows you to refine your audience by setting conditions for selected segments. Use AND to match all conditions or OR to match any condition, ensuring precise audience targeting for your broadcasts.';

export const earliestTimezoneForCountries = {
  [countryNamesAndValuesForBroadcastTargeting.US.value]: -4,
  [countryNamesAndValuesForBroadcastTargeting.ALL.value]: 14,
};

import React from 'react';
import { useTillerInviteContext } from '../hooks';
import styles from '../InvitationAccepted.module.scss';
import { ReactComponent as LinkIcon } from '../../../assets/svg/Link.svg';
import PageItem from 'src/pages/common/deploy-page-list/components/fb-page';
import { FbPage } from 'src/pages/fb-login/types';

const ListFbPages = () => {
  const { allFbPages, selectedPages, onCheckboxChange, onDeployPage } = useTillerInviteContext();
  return (
    <div className={styles.listFbPagesContainer}>
      <div className='heading'>Select Pages to link</div>
      <div className='fb-list'>
        {allFbPages?.currentUserPages.map((p: FbPage) => (
          <PageItem key={p.id} fbPage={p} {...{ selectedPages, handleCheckboxChange: onCheckboxChange, handleInstagramCheckboxChange: () => {} }} />
        ))}
      </div>
      <button className={styles.linkButton} type='button' onClick={onDeployPage} disabled={!Boolean(Object.keys(selectedPages).length)}>
        {/* <FaFacebook /> */}
        <LinkIcon style={{ width: '15px', height: '15px', marginRight: '5px' }} height={20} />
        Link facebook page
      </button>
    </div>
  );
};

export default ListFbPages;

/* eslint-disable @typescript-eslint/naming-convention */

import { AmplitudeEventProps, GenericObject } from '../types';
import { RegisterAmplitudeAnalytics } from './amplitude';
import { RegisterGAAnalytics } from './google-analytics';
export const amplitudeAnalytics = RegisterAmplitudeAnalytics();
export const gAAnalytics = RegisterGAAnalytics();

/*eslint-disable max-lines-per-function*/
const RegisterGAAmplitudeAnalytics = () => {
  return {
    logIn: () => {
      gAAnalytics.logIn();
      amplitudeAnalytics.logIn();
    },
    signUp: () => {
      gAAnalytics.signUp();
      amplitudeAnalytics.signUp();
    },
    signUpFailed: () => {
      gAAnalytics.signUpFailed();
      amplitudeAnalytics.signUpFailed();
    },
    userMenuCLick: () => {
      gAAnalytics.userMenuCLick();
      amplitudeAnalytics.userMenuCLick();
    },
    onCommentTabView: (tabName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.onCommentTabView(tabName, eventProps);
      amplitudeAnalytics.onCommentTabView(tabName, eventProps);
    },
    onDashboardCommentSectionClick: (tabName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.onDashboardCommentSectionClick(tabName, eventProps);
      amplitudeAnalytics.onDashboardCommentSectionClick(tabName, eventProps);
    },
    commentSearch: (searchType: string, searchValue: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.commentSearch(searchType, searchValue, eventProps);
      amplitudeAnalytics.commentSearch(searchType, searchValue, eventProps);
    },
    unlinkAPage: (pageName: string) => {
      gAAnalytics.unlinkAPage(pageName);
      amplitudeAnalytics.unlinkAPage(pageName);
    },
    readyToAmplify: () => {
      gAAnalytics.readyToAmplify();
      amplitudeAnalytics.readyToAmplify();
    },
    connectSocialMedia: (socialMediaName: string) => {
      gAAnalytics.connectSocialMedia(socialMediaName);
      amplitudeAnalytics.connectSocialMedia(socialMediaName);
    },
    actionOnComments: (actionType: string, eventProps: AmplitudeEventProps) => {
      switch (actionType) {
        case 'block':
          actionType = 'ban';
          break;
        case 'unblock':
          actionType = 'unban';
          break;
      }
      gAAnalytics.actionOnComments(actionType, eventProps);
      amplitudeAnalytics.actionOnComments(actionType, eventProps);
    },
    handleHomeConnectTOFB: () => {
      gAAnalytics.handleHomeConnectTOFB();
      amplitudeAnalytics.handleHomeConnectTOFB();
    },
    toggleTagStatus: (tagStatus: boolean, tagName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.toggleTagStatus(tagStatus, tagName, eventProps);
      amplitudeAnalytics.toggleTagStatus(tagStatus, tagName, eventProps);
    },
    toggleCampaignStatus: (campaignStatus: boolean, campaignName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.toggleCampaignStatus(campaignStatus, campaignName, eventProps);
      amplitudeAnalytics.toggleCampaignStatus(campaignStatus, campaignName, eventProps);
    },
    createTag: (tagName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.createTag(tagName, eventProps);
      amplitudeAnalytics.createTag(tagName, eventProps);
    },
    updateTag: (tagName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.updateTag(tagName, eventProps);
      amplitudeAnalytics.updateTag(tagName, eventProps);
    },
    createCampaign: (campaignName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.createCampaign(campaignName, eventProps);
      amplitudeAnalytics.createCampaign(campaignName, eventProps);
    },
    updateCampaign: (campaignName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.updateCampaign(campaignName, eventProps);
      amplitudeAnalytics.updateCampaign(campaignName, eventProps);
    },
    createKeywordGroup: (keywordName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.createKeywordGroup(keywordName, eventProps);
      amplitudeAnalytics.createKeywordGroup(keywordName, eventProps);
    },
    manageKeywordGroup: (keywordName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.manageKeywordGroup(keywordName, eventProps);
      amplitudeAnalytics.manageKeywordGroup(keywordName, eventProps);
    },
    trainedButtonClicked: (eventProps: AmplitudeEventProps) => {
      gAAnalytics.trainedButtonClicked(eventProps);
      amplitudeAnalytics.trainedButtonClicked(eventProps);
    },
    dateRangeClicks: (pageSection: string, selectedDateRage: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.dateRangeClicks(pageSection, selectedDateRage, eventProps);
      amplitudeAnalytics.dateRangeClicks(pageSection, selectedDateRage, eventProps);
    },
    clickGoToComment: (url: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.clickGoToComment(url, eventProps);
      amplitudeAnalytics.clickGoToComment(url, eventProps);
    },
    setAccountUserIdInConfig: (accountId: string, userId: string, externalUser: boolean) => {
      gAAnalytics.setAccountUserIdInConfig(accountId, userId, externalUser);
      amplitudeAnalytics.setAccountUserIdInConfig(accountId, userId, externalUser);
    },
    createTemplate: (templateName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.createTemplate(templateName, eventProps);
      amplitudeAnalytics.createTemplate(templateName, eventProps);
    },
    updateTemplate: (templateName: string, templateId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.updateTemplate(templateName, templateId, eventProps);
      amplitudeAnalytics.updateTemplate(templateName, templateId, eventProps);
    },
    deleteTemplate: (templateName: string, templateId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.deleteTemplate(templateName, templateId, eventProps);
      amplitudeAnalytics.deleteTemplate(templateName, templateId, eventProps);
    },
    createBroadcast: (broadcastName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.createBroadcast(broadcastName, eventProps);
      amplitudeAnalytics.createBroadcast(broadcastName, eventProps);
    },
    updateBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.updateBroadcast(broadcastName, broadcastId, eventProps);
      amplitudeAnalytics.updateBroadcast(broadcastName, broadcastId, eventProps);
    },
    archiveBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.archiveBroadcast(broadcastName, broadcastId, eventProps);
      amplitudeAnalytics.archiveBroadcast(broadcastName, broadcastId, eventProps);
    },
    activateBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.activateBroadcast(broadcastName, broadcastId, eventProps);
      amplitudeAnalytics.activateBroadcast(broadcastName, broadcastId, eventProps);
    },
    testBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.testBroadcast(broadcastName, broadcastId, eventProps);
      amplitudeAnalytics.testBroadcast(broadcastName, broadcastId, eventProps);
    },
    viewBroadcast: (broadcastName: string, broadcastId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.viewBroadcast(broadcastName, broadcastId, eventProps);
      amplitudeAnalytics.viewBroadcast(broadcastName, broadcastId, eventProps);
    },
    clickReportDownload: (reportName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.clickReportDownload(reportName, eventProps);
      amplitudeAnalytics.clickReportDownload(reportName, eventProps);
    },
    reportEmailSent: (reportName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.reportEmailSent(reportName, eventProps);
      amplitudeAnalytics.reportEmailSent(reportName, eventProps);
    },
    inboxMessageMoved: (fromTab: string, toTab: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.inboxMessageMoved(fromTab, toTab, eventProps);
      amplitudeAnalytics.inboxMessageMoved(fromTab, toTab, eventProps);
    },
    messageReplied: (repliedTo: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.messageReplied(repliedTo, eventProps);
      amplitudeAnalytics.messageReplied(repliedTo, eventProps);
    },
    inboxMessageStatusChanged: (fromStatus: string, toStatus: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.inboxMessageStatusChanged(fromStatus, toStatus, eventProps);
      amplitudeAnalytics.inboxMessageStatusChanged(fromStatus, toStatus, eventProps);
    },
    inboxMessageLabelAdded: (labelName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.inboxMessageLabelAdded(labelName, eventProps);
      amplitudeAnalytics.inboxMessageLabelAdded(labelName, eventProps);
    },
    inboxMessageLabelRemoved: (labelName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.inboxMessageLabelRemoved(labelName, eventProps);
      amplitudeAnalytics.inboxMessageLabelRemoved(labelName, eventProps);
    },
    bulkActionTaken: (messageIds: string, actionType: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.bulkActionTaken(messageIds, actionType, eventProps);
      amplitudeAnalytics.bulkActionTaken(messageIds, actionType, eventProps);
    },
    createSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.createSegment(segmentName, eventProps);
      amplitudeAnalytics.createSegment(segmentName, eventProps);
    },
    updateSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.updateSegment(segmentName, eventProps);
      amplitudeAnalytics.updateSegment(segmentName, eventProps);
    },
    deleteSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.deleteSegment(segmentName, eventProps);
      amplitudeAnalytics.deleteSegment(segmentName, eventProps);
    },
    fetchInboxMessages: (pageNumber: number, eventProps: AmplitudeEventProps) => {
      gAAnalytics.fetchInboxMessages(pageNumber, eventProps);
      amplitudeAnalytics.fetchInboxMessages(pageNumber, eventProps);
    },
    createInboxMessageLabel: (labelName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.createInboxMessageLabel(labelName, eventProps);
      amplitudeAnalytics.createInboxMessageLabel(labelName, eventProps);
    },
    showSessionsBtnClicked: (eventProps: AmplitudeEventProps) => {
      gAAnalytics.showSessionsBtnClicked(eventProps);
      amplitudeAnalytics.showSessionsBtnClicked(eventProps);
    },
    inboxManageLabelClicked: (eventProps: AmplitudeEventProps) => {
      gAAnalytics.inboxManageLabelClicked(eventProps);
      amplitudeAnalytics.inboxManageLabelClicked(eventProps);
    },
    viewConversation: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.viewConversation(platformUserId, eventProps);
      amplitudeAnalytics.viewConversation(platformUserId, eventProps);
    },
    platformUserUpdated: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.platformUserUpdated(platformUserId, eventProps);
      amplitudeAnalytics.platformUserUpdated(platformUserId, eventProps);
    },
    platformUserSubscribed: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.platformUserSubscribed(platformUserId, eventProps);
      amplitudeAnalytics.platformUserSubscribed(platformUserId, eventProps);
    },
    platformUserUnsubscribed: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.platformUserUnsubscribed(platformUserId, eventProps);
      amplitudeAnalytics.platformUserUnsubscribed(platformUserId, eventProps);
    },
    inboxUserDetailsClicked: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.inboxUserDetailsClicked(platformUserId, eventProps);
      amplitudeAnalytics.inboxUserDetailsClicked(platformUserId, eventProps);
    },
    inboxShowVariablesClicked: (eventProps: AmplitudeEventProps) => {
      gAAnalytics.inboxShowVariablesClicked(eventProps);
      amplitudeAnalytics.inboxShowVariablesClicked(eventProps);
    },
    audienceFiltersApplied: (
      isClearingFilters: boolean,
      eventProps: AmplitudeEventProps,
      countryList: string[] = [],
      stateList: string[] = [],
      cityList: string[] = [],
      genderList: string[] = [],
      ageRange: GenericObject | null,
      labelList: string[] = [],
      localeList: string[] = [],
      timezoneList: string[] = [],
    ) => {
      const countries = countryList.join(',');
      const states = stateList.join(',');
      const cities = cityList.join(',');
      const genders = genderList.join(',');
      const ages = ageRange ? (ageRange.from && ageRange.to ? `${ageRange.from}-${ageRange.to}` : ageRange.from ? `>${ageRange.from}` : `<${ageRange.to}`) : '';
      const labels = labelList.join(',');
      const locales = localeList.join(',');
      const timezones = timezoneList.join(',');
      gAAnalytics.audienceFiltersApplied(isClearingFilters, eventProps, countries, states, cities, genders, ages, labels, locales, timezones);
      amplitudeAnalytics.audienceFiltersApplied(isClearingFilters, eventProps, countries, states, cities, genders, ages, labels, locales, timezones);
    },
    audienceFansByModalOpen: (category: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceFansByModalOpen(category, eventProps);
      amplitudeAnalytics.audienceFansByModalOpen(category, eventProps);
    },
    audienceTabView: (tabName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceTabView(tabName, eventProps);
      amplitudeAnalytics.audienceTabView(tabName, eventProps);
    },
    audienceToggleUnavailabilityStatus: (isUnavailableHidden: boolean, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceToggleUnavailabilityStatus(isUnavailableHidden, eventProps);
      amplitudeAnalytics.audienceToggleUnavailabilityStatus(isUnavailableHidden, eventProps);
    },
    audienceSubscriptionDateChanged: (from: string, to: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceSubscriptionDateChanged(from, to, eventProps);
      amplitudeAnalytics.audienceSubscriptionDateChanged(from, to, eventProps);
    },
    audienceCreateSegment: (segmentName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceCreateSegment(segmentName, eventProps);
      amplitudeAnalytics.audienceCreateSegment(segmentName, eventProps);
    },
    audienceGoToInbox: (platformUserId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceGoToInbox(platformUserId, eventProps);
      amplitudeAnalytics.audienceGoToInbox(platformUserId, eventProps);
    },
    audienceSubscriptionChange: (platformUserId: string, isUnsubcribeEvent: boolean, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceSubscriptionChange(platformUserId, isUnsubcribeEvent, eventProps);
      amplitudeAnalytics.audienceSubscriptionChange(platformUserId, isUnsubcribeEvent, eventProps);
    },
    audienceModifyNewMemberLabels: (platformUserId: string, label: string, isAdd: boolean, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceModifyNewMemberLabels(platformUserId, label, isAdd, eventProps);
      amplitudeAnalytics.audienceModifyNewMemberLabels(platformUserId, label, isAdd, eventProps);
    },
    audienceAllNewMemberSelected: (pageName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceAllNewMemberSelected(pageName, eventProps);
      amplitudeAnalytics.audienceAllNewMemberSelected(pageName, eventProps);
    },
    audienceNewMemberClicked: (platformUserId: string, pageName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceNewMemberClicked(platformUserId, pageName, eventProps);
      amplitudeAnalytics.audienceNewMemberClicked(platformUserId, pageName, eventProps);
    },
    audienceSendMessageClicked: (pageName: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.audienceSendMessageClicked(pageName, eventProps);
      amplitudeAnalytics.audienceSendMessageClicked(pageName, eventProps);
    },
    audienceMessageSent: (platformUserIdsArray: string[], pageName: string, eventProps: AmplitudeEventProps) => {
      const platformUserIds = platformUserIdsArray.join(',');
      gAAnalytics.audienceMessageSent(platformUserIds, pageName, eventProps);
      amplitudeAnalytics.audienceMessageSent(platformUserIds, pageName, eventProps);
    },
    handleIngestClick: () => {
      gAAnalytics.handleIngestClickList();
      amplitudeAnalytics.handleIngestClickList();
    },
    downloadTemplateLists: (key: string) => {
      gAAnalytics.downloadStandardTemplate(key);
      amplitudeAnalytics.downloadStandardTemplate(key);
    },
    ingestStepClick: (stepkey: string) => {
      gAAnalytics.ingestSteps(stepkey);
      amplitudeAnalytics.ingestSteps(stepkey);
    },
    handleClickDoneIngest: () => {
      gAAnalytics.handleClickDoneIngest();
      amplitudeAnalytics.handleClickDoneIngest();
    },
    handleViewClickIngest: () => {
      gAAnalytics.handleViewClickIngest();
      amplitudeAnalytics.handleViewClickIngest();
    },
    handleRequestAccess: (pageName: string) => {
      gAAnalytics.handleRequestAccess(pageName);
      amplitudeAnalytics.handleRequestAccess(pageName);
    },
    handleCancelRequest: (id?: string) => {
      gAAnalytics.handleCancelRequest(id);
      amplitudeAnalytics.handleCancelRequest(id);
    },
    addSystemUser: (id?: string) => {
      gAAnalytics.addSystemUser(id);
      amplitudeAnalytics.addSystemUser(id);
    },
    createQrCode: (url: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.createQrCode(url, eventProps);
      amplitudeAnalytics.createQrCode(url, eventProps);
    },
    downloadQrCode: (filename: string) => {
      gAAnalytics.downloadQrCode(filename);
      amplitudeAnalytics.downloadQrCode(filename);
    },
    deleteQrCode: (utilityId: string) => {
      gAAnalytics.deleteQrCode(utilityId);
      amplitudeAnalytics.deleteQrCode(utilityId);
    },
    createShortUrl: (url: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.createShortUrl(url, eventProps);
      amplitudeAnalytics.createShortUrl(url, eventProps);
    },
    downloadShortUrlQrCode: (filename: string) => {
      gAAnalytics.downloadShortUrlQrCode(filename);
      amplitudeAnalytics.downloadShortUrlQrCode(filename);
    },
    updateShortUrl: (url: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.updateShortUrl(url, eventProps);
      amplitudeAnalytics.updateShortUrl(url, eventProps);
    },
    createMediaViewUrl: (url: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.createMediaViewUrl(url, eventProps);
      amplitudeAnalytics.createMediaViewUrl(url, eventProps);
    },
    deleteMediaViewUrl: (utilityId: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.deleteMediaViewUrl(utilityId, eventProps);
      amplitudeAnalytics.deleteMediaViewUrl(utilityId, eventProps);
    },
    uploadQrCode: (url: string, eventProps: AmplitudeEventProps) => {
      gAAnalytics.uploadQrCode(url, eventProps);
      amplitudeAnalytics.uploadQrCode(url, eventProps);
    },
  };
};
export const registerAmplifyAnalytics = RegisterGAAmplitudeAnalytics();
